import { GRAPHQL_ENDPOINT } from "./constants";
import request, { gql } from "graphql-request";

//USER SECTION

export const loadProfile = async (email, realmUser) => {
  const getProfileQuery = gql`
    query ($email: String) {
      user(query: { email: $email }) {
        _id
        agreedToTerms
        class
        count
        dataUse
        district
        email
        school
        user
        wellnesstimer
        voice
        displayName
        cleverId
        cleverToken
        schools
        badges {
          active
          creative
        }
        adultActionImages
        usesCastle
        coins
        maxDayStreak
        maxWeekStreak
        curDayStreak
        curWeekStreak
      }
    }
  `;
  const queryVariables = { email: email };

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };

  //Handle Session Token expired. - Sign out on session token expired.
  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      getProfileQuery,
      queryVariables,
      headers
    );
    return resp;
  } catch (error) {
    if (
      error.response.error_code &&
      error.response.error_code === "InvalidSession"
    ) {
      return "InvalidSession";
    }
    console.log(error);
    return error;
  }
};

export const loadProfilesFromIds = async (cleverIds, realmUser) => {
  const getProfilesQuery = gql`
    query ($cleverIds: [String]) {
      users(limit: 100000, query: { cleverId_in: $cleverIds }) {
        email
        _id
        user
        class
        dataUse
        cleverId
        displayName
      }
    }
  `;
  const queryVariables = { cleverIds };

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      getProfilesQuery,
      queryVariables,
      headers
    );
    return resp;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const dataTrendQuery = async (cleverIds, realmUser) => {
  const getProfilesQuery = gql`
    query ($cleverIds: [String]) {
      users(limit: 100000, query: { cleverId_in: $cleverIds }) {
        email
        _id
        user
        class
        dataUse
        cleverId
        displayName
      }
    }
  `;
  const queryVariables = { cleverIds };

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      getProfilesQuery,
      queryVariables,
      headers
    );
    return resp;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getClassAssociationsForStudents = async (
  districtData,
  schoolsData,
  allStudents,
  realmUser
) => {
  /*
    Args:
      -- districtData: a dictionary of data for the district.
      -- schoolsData: a list of dta for each unique school in the district.
      -- allStudents: a list of data for each unique student in the district.

    Returns: 
      Dictionary containing a mapping of student emails to their classes.
  */
  const getClassesQuery = gql`
    query ($district: String) {
      classes(limit: 100000, query: { district: $district }) {
        _id
        name
        school
        students
        teachers
        district
        cleverId
      }
    }
  `;
  const queryVariables = {
    district_in: districtData.cleverId
      ? [districtData.cleverId, districtData.name]
      : [districtData.name],
  };
  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };

  let classes;
  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      getClassesQuery,
      queryVariables,
      headers
    );
    classes = resp.classes;
  } catch (error) {
    console.log(error);
    return error;
  }

  const studentsInClasses = {};
  for (const school of schoolsData) {
    const classesInSchool = classes.filter(
      (c) =>
        school.classes.includes(c.name) || school.classes.includes(c.cleverId)
    );
    studentsInClasses[school.name] = {};
    for (const _cis of classesInSchool) {
      studentsInClasses[school.name][_cis.name] = allStudents.filter(
        (student) =>
          student.class &&
          student.class.length > 0 &&
          (student.class.includes(_cis.cleverId) ||
            student.class.includes(_cis.name))
      );
    }
  }

  console.log(classes);
  return studentsInClasses;
};

export const getClassAssociationsForStudents2 = async (
  districtData,
  schoolsData,
  allStudents,
  realmUser
) => {
  /*
    Args:
      -- districtData: a dictionary of data for the district.
      -- schoolsData: a list of dta for each unique school in the district.
      -- allStudents: a list of data for each unique student in the district.

    Returns: 
      Dictionary containing a mapping of student emails to their classes.
  */
  const getClassesQuery = gql`
    query ($district: String) {
      classes(limit: 100000, query: { district: $district }) {
        _id
        name
        school
        students
        teachers
        district
        cleverId
      }
    }
  `;
  const queryVariables = {
    district_in: districtData.cleverId
      ? [districtData.cleverId, districtData.name]
      : [districtData.name],
  };
  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };

  let classes;
  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      getClassesQuery,
      queryVariables,
      headers
    );
    classes = resp.classes;
  } catch (error) {
    console.log(error);
    return error;
  }

  const studentsInClasses = {};
  for (const school of schoolsData) {
    const classesInSchool = classes.filter(
      (c) =>
        school.classes.includes(c.name) || school.classes.includes(c.cleverId)
    );

    studentsInClasses[school.name] = {};

    for (const classData of classesInSchool) {
      if (classData.cleverId == null && classData.students) {
        studentsInClasses[school.name][classData.name] = classData.students;
      } else {
        studentsInClasses[school.name][classData.name] = allStudents
          .filter(
            (student) =>
              student.class &&
              student.class.length > 0 &&
                (
                  student.class.includes(classData.cleverId) ||
                    student.class.includes(classData.name)
                )
          )
          .map((student) => student.email); // Only add student emails
      }
    }
  }

  console.log(classes);
  return studentsInClasses;
};

export const loadProfiles = async (emails, realmUser) => {
  const getProfilesQuery = gql`
    query ($emails: [String]) {
      users(limit: 100000, query: { email_in: $emails }) {
        email
        _id
        user
        class
        dataUse
        cleverId
        displayName
      }
    }
  `;
  const queryVariables = { emails: emails };

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      getProfilesQuery,
      queryVariables,
      headers
    );
    return resp;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const loadProfiles2 = async (queryInput, realmUser) => {
  const getProfiles2Query = gql`
    query ($queryInput: UserQueryInput) {
      users(limit: 100000, query: $queryInput) {
        email
        _id
        user
        class
        school
        schools
        displayName
      }
    }
  `;

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      getProfiles2Query,
      { queryInput },
      headers
    );
    return resp;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const filterAdminData = async (adminData, realmUser) => {
  const getProfilesQuery = gql`
    query ($adminEmails: [String]) {
      users(limit: 100000, query: { email_in: $adminEmails }) {
        email
        _id
        user
        class
        dataUse
        cleverId
        displayName
        schools
      }
    }
  `;
  const queryVariables = { adminEmails: adminData };

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  let users;
  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      getProfilesQuery,
      queryVariables,
      headers
    );
    users = resp.users;
  } catch (error) {
    console.log(error);
    return error;
  }

  const toUpdate = users;
  const toAdd = users.filter((user) => !toUpdate.includes(user.email));
  return { toUpdate, toAdd };
};

export const insertProfiles = async (userData, realmUser) => {
  // GraphQL query to fetch mode analytics as well as the category analytics.
  const insertProfilesQuery = gql`
    mutation ($data: [UserInsertInput!]!) {
      insertManyUsers(data: $data) {
        insertedIds
      }
    }
  `;
  const queryVariables = { data: userData };

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  //Handle Session Token expired. - Sign out on session token expired.
  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      insertProfilesQuery,
      queryVariables,
      headers
    );
    return resp;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const updateProfile = async (email, realmUser, setObject) => {
  const updateProfileQuery = gql`
    mutation EditProfile($query: UserQueryInput!, $set: UserUpdateInput!) {
      updateOneUser(query: $query, set: $set) {
        agreedToTerms
        class
        count
        dataUse
        district
        email
        school
        user
        usesCastle
      }
    }
  `;
  // Query variables that will be used to perform the analytics from a particular
  // date to a particular date.
  const queryVariables = {
    query: {
      email: email,
    },
    set: setObject,
  }; //authentication.currentUser.email};

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  //TODO: Handle Session Token expired. - Sign out on session token expired.
  // console.log("Update Called");
  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      updateProfileQuery,
      queryVariables,
      headers
    );
    // console.log(resp);
    return resp;
  } catch (error) {
    console.log(error);
    if (error.response.error_code === "InvalidSession") {
      return "InvalidSession";
    }
    return error;
  }
};

export const updateProfiles = async (query, realmUser, setObject) => {
  const updateProfilesQuery = gql`
    mutation EditProfiles($query: UserQueryInput!, $set: UserUpdateInput!) {
      updateManyUsers(query: $query, set: $set) {
        matchedCount
        modifiedCount
      }
    }
  `;
  // Query variables that will be used to perform the analytics from a particular
  // date to a particular date.
  const queryVariables = {
    query: query,
    set: setObject,
  }; //authentication.currentUser.email};

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  //TODO: Handle Session Token expired. - Sign out on session token expired.
  // console.log("Update Called");
  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      updateProfilesQuery,
      queryVariables,
      headers
    );
    // console.log(resp);
    return resp;
  } catch (error) {
    console.log(error);
    return error;
  }
};

// WILLY
// A helper function to execute GraphQL queries
export const fetchUserSchool = async (email, realmUser) => {
  const getUserSchoolQuery = gql`
    query GetUserSchool($data: UserQueryInput!) {
      user(query: $data) {
        school
      }
    }
  `;

  const queryVariables = {
    data: {
      email: email,
    },
  };

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };

  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      getUserSchoolQuery,
      queryVariables,
      headers
    );
    return resp.user.school; // Or just return resp if you want the entire response object
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const fetchClassesBySchoolName = async (schoolName, realmUser) => {
  const getClassesQuery = gql`
    query GetClassesBySchoolName($data: SchoolQueryInput!) {
      school(query: $data) {
        classes
      }
    }
  `;

  const queryVariables = {
    data: {
      name: schoolName,
    },
  };

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };

  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      getClassesQuery,
      queryVariables,
      headers
    );
    return resp.school.classes; // Or just return resp if you want the entire response object
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const fetchStudentsFromId = async (ids, realmUser) => {
  const getStudentsQuery = gql`
    query GetStudentsQueryFromId($ids: [String]!) {
      users(query: { cleverId_in: $ids }) {
        cleverId
        displayName
        email
      }
    }
  `;

  const queryVariables = { ids };
  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      getStudentsQuery,
      queryVariables,
      headers
    );
    return resp.users; // Or just return resp if you want the entire response object
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const fetchTeachersFromId = async (ids, realmUser) => {
  const getTeachersQuery = gql`
    query GetTeachersQueryFromId($ids: [String]!) {
      users(query: { cleverId_in: $ids }) {
        cleverId
        displayName
        email
      }
    }
  `;

  const queryVariables = { ids };
  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      getTeachersQuery,
      queryVariables,
      headers
    );
    return resp.users; // Or just return resp if you want the entire response object
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const fetchClassesBySchoolId = async (schoolId, realmUser) => {
  const getClassesQuery = gql`
    query GetClassesBySchoolId($schoolId: String!) {
      class(query: { school: $schoolId }) {
        name
        students
        teachers
      }
    }
  `;

  const queryVariables = {
    schoolId,
  };

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };

  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      getClassesQuery,
      queryVariables,
      headers
    );
    return resp.class; // Or just return resp if you want the entire response object
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const fetchClassesWithName = async (schoolId, name, realmUser) => {
  const getClassesQuery = gql`
    query GetClassesFromName($schoolId: String!, $name: String!) {
      class(query: { school: $schoolId, name: $name }) {
        name
        students
        teachers
      }
    }
  `;

  const queryVariables = {
    name,
    schoolId,
  };

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };

  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      getClassesQuery,
      queryVariables,
      headers
    );
    return resp.class; // Or just return resp if you want the entire response object
  } catch (error) {
    console.log(error);
    return error;
  }
};

export async function fetchStudentsData(userID, realmUser) {
  const studentDataQuery = `
    query FetchWellnessDataByUserID($userID: String!) {
      wellnesses(query: { userID: $userID }) {
        date
        emotion
      }
    }
  `;

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };

  try {
    const response = await request(
      GRAPHQL_ENDPOINT,
      studentDataQuery,
      { userID },
      headers
    );
    return response ? response.wellnesses : [];
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function fetchStudentEmailToIDMapping(realmUser) {
  const studentQuery = gql`
    query getAllStudents {
      users(query: { user: "student" }, limit: 1000) {
        _id
        email
      }
    }
  `;

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };

  try {
    const response = await request(GRAPHQL_ENDPOINT, studentQuery, {}, headers);
    const students = response.users;
    let emailToIDMapping = {};

    students.forEach((student) => {
      emailToIDMapping[student.email] = student._id;
    });

    return emailToIDMapping;
  } catch (error) {
    console.error("Error fetching student email-ID mapping:", error);
    throw error;
  }
}

export async function fetchStudentIDfromEmail(email, realmUser) {
  const studentDataQuery = `
  query GetUserIDByEmail($email: String!) {
      user(query: { email: $email }) {
          _id
      }
  }
`;
  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };

  try {
    const response = await request(
      GRAPHQL_ENDPOINT,
      studentDataQuery,
      { email },
      headers
    );
    return response ? response : [];
  } catch (error) {
    console.log(error);
    return "";
  }
}

export async function fetchStudentsFromClass(className, realmUser) {
  const classQuery = gql`
    query ($className: String!) {
      class(query: { name: $className }) {
        students
      }
    }
  `;

  const queryVariables = { className: className };

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  try {
    const response = await request(
      GRAPHQL_ENDPOINT,
      classQuery,
      queryVariables,
      headers
    );
    return response.class ? response.class.students : [];
  } catch (error) {
    console.log(error);
    return [];
  }
}

export const loadStudentsFromSchool = async (schoolName, realmUser) => {
  // GraphQL query to fetch all students from a specific school.
  const getStudentsFromSchoolQuery = gql`
    query ($school: String) {
      users(query: { school: $school, user: "student" }) {
        email
      }
    }
  `;

  const queryVariables = { school: schoolName };

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };

  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      getStudentsFromSchoolQuery,
      queryVariables,
      headers
    );
    return resp.users; // Adjust this if the GraphQL response structure is different
  } catch (error) {
    console.log(error);
    return error;
  }
};

//SCHOOL SECTION

export const loadSchools = async (districtName, schoolNames, realmUser) => {
  const getSchoolsQuery = gql`
    query ($schoolNames: [String], $district: String) {
      schools(query: { name_in: $schoolNames, district: $district }) {
        _id
        name
        district
        admins
      }
    }
  `;
  const queryVariables = { classNames: schoolNames, school: districtName };

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      getSchoolsQuery,
      queryVariables,
      headers
    );
    return resp;
  } catch (error) {
    console.log(error);
    return error;
  }
};
export const loadSchools2 = async (data, realmUser) => {
  // GraphQL query to fetch mode analytics as well as the category analytics.
  const getSchoolQuery = gql`
    query ($data: SchoolQueryInput) {
      schools(query: $data) {
        _id
        admins
        classes
        name
        district
        cleverId
      }
    }
  `;
  const queryVariables = { data: data };
  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      getSchoolQuery,
      queryVariables,
      headers
    );
    return resp;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const loadSchoolsFromCleverId = async (districtId, realmUser) => {
  const getSchoolQuery = gql`
    query ($cleverId: String) {
      schools(query: { district: $cleverId }) {
        _id
        admins
        classes
        name
        district
        cleverId
      }
    }
  `;
  const queryVariables = { cleverId: districtId };
  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      getSchoolQuery,
      queryVariables,
      headers
    );
    return resp;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const loadSchoolFromCleverId = async (schoolID, realmUser) => {
  const getSchoolQuery = gql`
    query ($cleverId: String) {
      schools(query: { cleverId: $cleverId }) {
        _id
        admins
        classes
        name
        district
        cleverId
      }
    }
  `;
  const queryVariables = { cleverId: schoolID };
  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      getSchoolQuery,
      queryVariables,
      headers
    );
    return resp;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const loadSchool = async (data, realmUser) => {
  // GraphQL query to fetch mode analytics as well as the category analytics.
  const getSchoolQuery = gql`
    query ($data: SchoolQueryInput!) {
      school(query: $data) {
        _id
        admins
        classes
        name
      }
    }
  `;
  const queryVariables = { data: data };
  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      getSchoolQuery,
      queryVariables,
      headers
    );
    return resp;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const fetchClassNameByCleverId = async (cleverId, realmUser) => {
  const getDistrictQuery = gql`
    query ($cleverId: String) {
      class(query: { cleverId: $cleverId }) {
        _id
        name
        cleverId
      }
    }
  `;
  const queryVariables = { cleverId };
  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      getDistrictQuery,
      queryVariables,
      headers
    );
    return resp;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const fetchSchoolIDsByNames = async (schoolNames, realmUser) => {
  const getSchoolIDsQuery = gql`
    query ($data: SchoolQueryInput!) {
      schools(query: $data) {
        _id
      }
    }
  `;
  const queryVariables = {
    data: {
      name_in: schoolNames,
    },
  };
  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };

  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      getSchoolIDsQuery,
      queryVariables,
      headers
    );
    return resp.schools.map((school) => school._id);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const fetchSchoolsInfoByID = async (schoolIDs, realmUser) => {
  const getSchoolsInfoQuery = gql`
    query ($data: SchoolQueryInput!) {
      schools(query: $data) {
        _id
        name
        district
        classes
      }
    }
  `;
  const queryVariables = {
    data: {
      _id_in: schoolIDs,
    },
  };
  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };

  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      getSchoolsInfoQuery,
      queryVariables,
      headers
    );
    return resp.schools;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const fetchAllSchoolsInDistrict = async (district, realmUser) => {
  const getSchoolsInfoQuery = gql`
    query ($data: SchoolQueryInput!) {
      schools(query: $data) {
        _id
        name
        district
        classes
        cleverId
        admins
      }
    }
  `;
  const queryVariables = {
    data: {
      district,
    },
  };
  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };

  let schools;
  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      getSchoolsInfoQuery,
      queryVariables,
      headers
    );

    schools = resp.schools;
  } catch (error) {
    console.log(error);
    return error;
  }

  const getArchivedClassesQuery = gql`
    query ($district: String) {
      classes(query: { district: $district, archived: true }) {
        _id
        name
        school
        district
        students
        teachers
        cleverId
        archived
      }
    }
  `;
  const qVars = { district };

  let classes;
  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      getArchivedClassesQuery,
      qVars,
      headers
    );

    classes = resp.classes;
  } catch (error) {
    console.log(error);
    return error;
  }

  const classesBySchool = {};
  for (const cref of classes) {
    if (!(cref.school in classesBySchool)) {
      classesBySchool[cref.school] = [];
    }
    classesBySchool[cref.school].push(cref.cleverId);
  }

  for (const school of schools) {
    if (school.cleverId in classesBySchool) {
      school.classes = school.classes.concat(classesBySchool[school.cleverId]);
    }
  }

  return schools;
};

export const fetchSchoolsInfoByCleverId = async (schoolIds, realmUser) => {
  const getSchoolsInfoQuery = gql`
    query ($data: SchoolQueryInput!) {
      schools(query: $data) {
        _id
        name
        district
        classes
        cleverId
        admins
      }
    }
  `;
  const queryVariables = {
    data: {
      cleverId_in: schoolIds,
    },
  };
  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };

  let schools;
  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      getSchoolsInfoQuery,
      queryVariables,
      headers
    );

    schools = resp.schools;
  } catch (error) {
    console.log(error);
    return error;
  }

  const getArchivedClassesQuery = gql`
    query ($schoolIds: [String]!) {
      classes(query: { school_in: $schoolIds, archived: true }) {
        _id
        name
        school
        district
        students
        teachers
        cleverId
        archived
      }
    }
  `;
  const qVars = { schoolIds };

  let classes;
  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      getArchivedClassesQuery,
      qVars,
      headers
    );

    classes = resp.classes;
  } catch (error) {
    console.log(error);
    return error;
  }

  const classesBySchool = {};
  for (const cref of classes) {
    if (!(cref.school in classesBySchool)) {
      classesBySchool[cref.school] = [];
    }
    classesBySchool[cref.school].push(cref.cleverId);
  }

  for (const school of schools) {
    if (school.cleverId in classesBySchool) {
      school.classes = school.classes.concat(classesBySchool[school.cleverId]);
    }
  }

  return schools;
};

export const fetchAllInformationFor123WellnessUser = async (realmUser) => {
  const fetchInfoQuery = gql`
    query () {
      districts() {

      }
    }
  `;
};

export const fetchSchoolsInfoByName = async (district, names, realmUser) => {
  const getSchoolsInfoQuery = gql`
    query ($data: SchoolQueryInput!) {
      schools(query: $data) {
        _id
        name
        district
        classes
        cleverId
        admins
      }
    }
  `;
  const queryVariables = {
    data: {
      district,
      name_in: names,
    },
  };
  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };

  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      getSchoolsInfoQuery,
      queryVariables,
      headers
    );

    return resp.schools;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const updateSchool = async (
  schoolName,
  districtName,
  realmUser,
  setObject
) => {
  const updateSchoolQuery = gql`
    mutation EditSchool($query: SchoolQueryInput!, $set: SchoolUpdateInput!) {
      updateOneSchool(query: $query, set: $set) {
        name
      }
    }
  `;
  const queryVariables = {
    query: {
      name: schoolName,
      district: districtName,
    },
    set: setObject,
  };

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  // console.log("Update Called");
  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      updateSchoolQuery,
      queryVariables,
      headers
    );
    return resp;
  } catch (error) {
    console.log(error);
    return error;
  }
};
export const updateSchoolByID = async (schoolID, realmUser, setObject) => {
  const updateSchoolQuery = gql`
    mutation EditSchool($query: SchoolQueryInput!, $set: SchoolUpdateInput!) {
      updateOneSchool(query: $query, set: $set) {
        name
      }
    }
  `;

  const queryVariables = {
    query: {
      _id: schoolID,
    },
    set: setObject,
  };

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };

  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      updateSchoolQuery,
      queryVariables,
      headers
    );
    return resp;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const removeClassFromSchool = async (
  schoolName,
  districtName,
  realmUser,
  classToRemove
) => {
  // Load the current list of classes
  const currentClassesResponse = await loadAllClassesFromSchoolV2(
    schoolName,
    districtName,
    realmUser
  );

  // Extract the classes array
  let classes = currentClassesResponse.classes || [];

  // Remove the classToRemove from the classes array
  const updatedClasses = classes
    .map(c => (typeof c === "string" ? c : c.name)) // Ensure only names are stored
    .filter(name => name !== classToRemove);
  console.log("updated classes", updatedClasses);

  // Define the GraphQL mutation
  const updateSchoolQuery = gql`
    mutation EditSchool($query: SchoolQueryInput!, $set: SchoolUpdateInput!) {
      updateOneSchool(query: $query, set: $set) {
        name
      }
    }
  `;

  // Prepare the variables for the mutation
  const queryVariables = {
    query: {
      name: schoolName,
      district: districtName,
    },
    set: {
      classes: updatedClasses,
    },
  };

  // Set the headers for authentication
  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  console.log("Update Called");

  try {
    // Send the mutation request
    const resp = await request(
      GRAPHQL_ENDPOINT,
      updateSchoolQuery,
      queryVariables,
      headers
    );
    return resp;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const insertSchool = async (schoolData, realmUser) => {
  // GraphQL query to fetch mode analytics as well as the category analytics.
  const insertSchoolQuery = gql`
    mutation ($data: SchoolInsertInput!) {
      insertOneSchool(data: $data) {
        name
      }
    }
  `;
  const queryVariables = { data: schoolData };

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  //Handle Session Token expired. - Sign out on session token expired.
  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      insertSchoolQuery,
      queryVariables,
      headers
    );
    // return resp;
  } catch (error) {
    console.log(error);
    return error;
  }

  const updateDistrict = gql`
    mutation ($school: displayName) {
      updateOneDistrict(query: { district: ${schoolData.district} }, push: {schools: $school}) {
        name
      }
    }
  `;
  try {
    await request(
      GRAPHQL_ENDPOINT,
      updateDistrict,
      { school: schoolData.name },
      headers
    );
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const insertSchoolV2 = async (schoolData, realmUser) => {
  // GraphQL query to insert a new school
  const insertSchoolQuery = gql`
    mutation ($data: SchoolInsertInput!) {
      insertOneSchool(data: $data) {
        name
      }
    }
  `;
  const queryVariables = { data: schoolData };
  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };

  try {
    // Insert the new school
    const resp = await request(
      GRAPHQL_ENDPOINT,
      insertSchoolQuery,
      queryVariables,
      headers
    );

    // Assuming the schoolData contains the district ID and the school name
    // const districtId = schoolData.districtId; // Ensure this is the correct field
    const schoolName = schoolData.name;
    let district = await loadDistrict(schoolData.district, realmUser);
    // Update the district with the new school
    // console.log(district);
    let schools = district.district.schools;
    schools.push(schoolName);
    const setObject = {
      schools, // Assuming you want to add this school to the list
    };
    // console.log(setObject);
    // Call the updateDistrictV2 function
    await updateDistrict(schoolData.district, realmUser, setObject);

    return resp;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const deleteSchool = async (districtName, schoolName, realmUser) => {
  // GraphQL query to fetch mode analytics as well as the category analytics.
  const deleteSchoolQuery = gql`
    mutation ($data: SchoolQueryInput!) {
      deleteOneSchool(query: $data) {
        name
      }
    }
  `;
  const queryVariables = { data: { district: districtName, name: schoolName } };

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  //Handle Session Token expired. - Sign out on session token expired.
  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      deleteSchoolQuery,
      queryVariables,
      headers
    );
    return resp;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const deleteSchools = async (query, realmUser) => {
  // GraphQL query to fetch mode analytics as well as the category analytics.
  const deleteSchoolsQuery = gql`
    mutation ($data: SchoolQueryInput!) {
      deleteManySchools(query: $data) {
        deletedCount
      }
    }
  `;
  const queryVariables = { data: query };

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  //Handle Session Token expired. - Sign out on session token expired.
  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      deleteSchoolsQuery,
      queryVariables,
      headers
    );
    return resp;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const fetchSchoolByID = async (id, realmUser) => {
  const getSchoolQuery = gql`
    query GetSchool($data: SchoolQueryInput!) {
      school(query: $data) {
        _id
        name
        classes
      }
    }
  `;

  const queryVariables = {
    data: {
      _id: id,
    },
  };

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };

  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      getSchoolQuery,
      queryVariables,
      headers
    );
    return resp.school;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const fetchSchoolIDByName = async (schoolName, realmUser) => {
  const getSchoolIDQuery = gql`
    query GetSchoolIDByName($data: SchoolQueryInput!) {
      schools(query: $data) {
        _id
      }
    }
  `;

  const queryVariables = {
    data: {
      name: schoolName, // Adjust if the field for school name in your schema is different
    },
  };

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };

  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      getSchoolIDQuery,
      queryVariables,
      headers
    );
    if (resp.schools && resp.schools.length > 0) {
      return resp.schools[0]._id;
    }
    throw new Error("School not found");
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const fetchStudentByEmail = async (email, realmUser) => {
  const getStudentQuery = gql`
    query GetStudent($data: UserQueryInput!) {
      user(query: $data) {
        _id
        user
        email
        school
        dataUse
      }
    }
  `;

  const queryVariables = {
    data: {
      email: email,
    },
  };

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };

  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      getStudentQuery,
      queryVariables,
      headers
    );
    if (resp.user && resp.user.user === "student") {
      return resp.user;
    } else {
      throw new Error("Fetched user is not a student.");
    }
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const fetchWellnessHistoryByEmail = async (email, realmUser) => {
  const getWellnessHistoryQuery = gql`
    query GetWellnessHistory($data: WellnessQueryInput!) {
      wellnesses(query: $data) {
        date
        emotion
      }
    }
  `;

  const queryVariables = {
    data: {
      email: email, // Assuming you have an 'email' field in your wellness type.
    },
  };

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };

  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      getWellnessHistoryQuery,
      queryVariables,
      headers
    );
    return resp.wellnesses;
  } catch (error) {
    console.log(error);
    return error;
  }
};

//CLASSES SECTION
export const loadClasses = async (schoolName, classNames, realmUser) => {
  const getClassesQuery = gql`
    query ($classNames: [String], $school: String) {
      classes(query: { name_in: $classNames, school: $school }) {
        _id
        name
        school
        students
        teachers
        district
      }
    }
  `;
  const queryVariables = { classNames: classNames, school: schoolName };
  //console.log(getClassesQuery,queryVariables)
  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      getClassesQuery,
      queryVariables,
      headers
    );
    return resp;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const loadAllSchools = async (realmUser) => {
  const getClassesQuery = gql`
    query {
      schools (limit: 1000) {
        _id
        name
        admins
        classes
        district
        cleverId
      }
    }
  `;

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  try {
    return await request(GRAPHQL_ENDPOINT, getClassesQuery, {}, headers);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const loadAllClassesFromSchools = async (schoolIds, realmUser) => {
  const getClassesQuery = gql`
    query ($schoolIds: [String]!) {
      classes(query: { school_in: $schoolIds }) {
        _id
        name
        school
        students
        teachers
        district
        cleverId
      }
    }
  `;
  const queryVariables = { schoolIds };
  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      getClassesQuery,
      queryVariables,
      headers
    );
    return resp;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const loadClassesFromCleverSchools = async (
  schoolIds,
  classIds,
  realmUser
) => {
  const getClassesQuery = gql`
    query ($schoolIds: [String]!, $classIds: [String]!) {
      classes(query: { school_in: $schoolIds, cleverId_in: $classIds }) {
        _id
        name
        school
        students
        teachers
        district
        cleverId
      }
    }
  `;
  const queryVariables = { schoolIds, classIds };
  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      getClassesQuery,
      queryVariables,
      headers
    );
    return resp;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const loadAllClassesFromUser = async (classIds, realmUser) => {
  const getClassesQuery = gql`
    query ($classIds: [String!]) {
      classes(query: { cleverId_in: $classIds }) {
        _id
        name
        students
        teachers
        cleverId
      }
    }
  `;
  const getClassesQuery2 = gql`
    query ($classIds: [String!]) {
      classes(query: { name_in: $classIds }) {
        _id
        name
        students
        teachers
        cleverId
      }
    }
  `;

  const queryVariables = { classIds };
  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };

  let response;
  try {
    response = await request(
      GRAPHQL_ENDPOINT,
      getClassesQuery,
      queryVariables,
      headers
    );
  } catch (error) {
    console.log(error);
    return error;
  }

  try {
    const tmpResp = await request(
      GRAPHQL_ENDPOINT,
      getClassesQuery2,
      queryVariables,
      headers
    );
    response.classes = response.classes.concat(tmpResp.classes);
  } catch (error) {
    console.log(error);
    return error;
  }

  return response;
};

export const loadNonCleverClassesFromCleverSchool = async (
  cleverId,
  realmUser
) => {
  const getClassesQuery = gql`
    query ($cleverId: String!) {
      classes(query: { school: $cleverId, cleverId_exists: false }) {
        _id
        name
        students
        teachers
        cleverId
      }
    }
  `;

  const queryVariables = { cleverId };
  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };

  try {
    return await request(
      GRAPHQL_ENDPOINT,
      getClassesQuery,
      queryVariables,
      headers
    );
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const loadAllClassesFromNonCleverUser = async (
  district,
  names,
  realmUser
) => {
  const getClassesQuery = gql`
    query ($names: [String]!, $district: String!) {
      classes(query: { district: $district, name_in: $names }) {
        _id
        name
        students
        teachers
        cleverId
        district
        school
      }
    }
  `;

  const queryVariables = { district, names };
  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };

  try {
    return await request(
      GRAPHQL_ENDPOINT,
      getClassesQuery,
      queryVariables,
      headers
    );
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const loadAllClassesFromSchool = async (val, realmUser) => {
  const getClassesQuery = gql`
    query ($name: String!) {
      classes(query: { school: $name }) {
        _id
        name
        students
        teachers
        cleverId
        district
      }
    }
  `;
  const queryVariables = { name: val };
  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      getClassesQuery,
      queryVariables,
      headers
    );
    return resp;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const loadAllClassesFromSchoolV2 = async (
  schoolName,
  districtName,
  realmUser
) => {
  const getClassesQuery = gql`
    query ($schoolName: String!, $districtName: String!) {
      classes(query: { school: $schoolName, district: $districtName }) {
        _id
        name
        students
        teachers
        cleverId
        district
        school
      }
    }
  `;
  const queryVariables = { schoolName, districtName };
  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };

  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      getClassesQuery,
      queryVariables,
      headers
    );
    return resp;
  } catch (error) {
    console.log(error);
    return error;
  }
};

// export const updateClassRoster = async (
//   classData,
//   students,
//   teachers,
//   allStudents,
//   allTeachers
// ) => {
//   for (const c of classData) {

//   }
//   // for (const )
// };
export const getUsersBySchoolAndDistrict = async (
  schoolName,
  districtName,
  realmUser
) => {
  const getUsersQuery = gql`
    query ($schoolName: String!, $districtName: String!) {
      users(
        query: { school: $schoolName, district: $districtName }
        limit: 100000
      ) {
        user
        displayName
        email
        class
        school
        district
      }
    }
  `;

  const queryVariables = { schoolName, districtName };
  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };

  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      getUsersQuery,
      queryVariables,
      headers
    );

    // Ensure the output format is consistent
    const formattedUsers = resp.users.map((user) => ({
      user: user.user,
      displayName: user.displayName,
      email: user.email,
      class: user.class,
      school: user.school,
      district: user.district,
    }));

    return formattedUsers;
  } catch (error) {
    console.log(error);
    return error;
  }
};
export const getNonCleverUsersFromSchool = async (
  schoolName,
  classes,
  realmUser
) => {
  const getUsersQuery = gql`
    query ($schoolName: String!, $classes: [String]!) {
      classes(query: { school: $schoolName, name_in: $classes }) {
        name
        students
        teachers
      }
    }
  `;
  const getUserData = gql`
    query ($users: [String]!) {
      users(query: { email_in: $users }, limit: 100000) {
        user
        displayName
        email
        class
        school
        district
      }
    }
  `;

  const queryVariables = { schoolName, classes };
  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      getUsersQuery,
      queryVariables,
      headers
    );

    let allUsers = [];
    for (const c of resp.classes) {
      allUsers = allUsers.concat(c.students.concat(c.teachers));
    }
    allUsers = Array.from(new Set(allUsers));

    const resp2 = await request(
      GRAPHQL_ENDPOINT,
      getUserData,
      { users: allUsers },
      headers
    );

    return resp2.users;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const addToClassAndProvision = async (
  email,
  classData,
  userData,
  realmUser,
  addToClass = true
) => {
  if (!userData) {
    // console.log(email);
    const userData = [
      {
        email,
        count: 0,
        user: "student",
        dataUse: true,
        agreedToTerms: false,
        class: [classData.name],
        school: classData.school,
        schools: [classData.school],
        wellnesstimer: "30 seconds",
        district: classData.district,
      },
    ];
    await insertProfiles(userData, realmUser);
  } else {
    const { class: classes } = userData;
    classes.append(classData.name);
    await updateProfile(email, realmUser, { class: classes });
  }

  if (addToClass) {
    await updateClass(classData.school, classData.name, realmUser, {
      students: classData.students.concat(email),
    });
  }
};

export const removeUserFromClass = async (
  email,
  classData,
  userData,
  realmUser
) => {
  if (!userData) {
    return;
  }
  const copyData = JSON.parse(JSON.stringify(classData.students));
  const studentIndex = copyData.indexOf(email);
  copyData.splice(studentIndex, 1);
  await updateClass(classData.school, classData.name, realmUser, {
    students: copyData,
  });

  const { class: classes } = userData;
  classes.splice(classes.indexOf(classData.name));
  await updateProfile(email, realmUser, { class: classes });
};

export const getCleverUsersFromSchool = async (cleverId, realmUser) => {
  const getUsersQuery = gql`
    query ($idList: [String]!) {
      users(limit: 1000000, query: { schools_in: $idList }) {
        user
        cleverId
        displayName
        email
        class
        school
        district
      }
    }
  `;
  const queryVariables = { idList: [cleverId] };
  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      getUsersQuery,
      queryVariables,
      headers
    );
    return resp;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const loadClassesFromCleverIds = async (
  schoolName,
  classIds,
  realmUser
) => {
  const getClassesQuery = gql`
    query ($classNames: [String], $school: String) {
      classes(query: { cleverId_in: $classNames, school: $school }) {
        _id
        name
        school
        students
        teachers
        district
        cleverId
      }
    }
  `;
  const queryVariables = { classNames: classIds, school: schoolName };
  //console.log(getClassesQuery,queryVariables)
  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      getClassesQuery,
      queryVariables,
      headers
    );
    return resp;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const loadClasses2 = async (data, realmUser) => {
  const getClassesQuery = gql`
    query ($data: ClassQueryInput!) {
      classes(query: $data) {
        _id
        name
        school
        students
        teachers
        district
      }
    }
  `;
  const queryVariables = { data: data };
  // console.log(getClassesQuery, queryVariables);
  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      getClassesQuery,
      queryVariables,
      headers
    );
    return resp;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const updateClassName = async (id, name, realmUser, usingClever) => {
  const updateClassQuery = gql`
    mutation EditClassName($id: String!, $newName: String!) {
      updateOneClass(query: { ${
        usingClever ? "cleverId" : "_id"
      }: $id }, set: { name: $newName }) {
        name
        school
        students
        teachers
      }
    }
  `;
  const variables = { id, newName: name };
  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      updateClassQuery,
      variables,
      headers
    );
    return resp;
  } catch (error) {
    console.log(error);
    if (error.response.error_code === "InvalidSession") {
      return "InvalidSession";
    }
    return error;
  }
};

export const updateClass = async (school, classname, realmUser, setObject) => {
  const updateClassQuery = gql`
    mutation EditClass($query: ClassQueryInput!, $set: ClassUpdateInput!) {
      updateOneClass(query: $query, set: $set) {
        name
        school
        students
        teachers
      }
    }
  `;
  const queryVariables = {
    query: {
      name: classname,
      school: school,
    },
    set: setObject,
  };

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  // console.log("Update Called");
  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      updateClassQuery,
      queryVariables,
      headers
    );
    return resp;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const updateClassByID = async (id, classname, realmUser, setObject) => {
  const updateClassQuery = gql`
    mutation EditClass($query: ClassQueryInput!, $set: ClassUpdateInput!) {
      updateOneClass(query: $query, set: $set) {
        name
        school
        students
        teachers
      }
    }
  `;
  const queryVariables = {
    query: {
      name: classname,
      _id: id,
    },
    set: setObject,
  };

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  // console.log("Update Called");
  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      updateClassQuery,
      queryVariables,
      headers
    );
    // console.log(JSON.stringify(resp));
    return resp;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const insertClass = async (classData, realmUser) => {
  // GraphQL query to fetch mode analytics as well as the category analytics.
  const insertClassQuery = gql`
    mutation ($data: ClassInsertInput!) {
      insertOneClass(data: $data) {
        name
      }
    }
  `;
  const queryVariables = { data: classData };

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  //Handle Session Token expired. - Sign out on session token expired.
  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      insertClassQuery,
      queryVariables,
      headers
    );
    return resp;
  } catch (error) {
    console.log(error);
    return error;
  }
};
//TODO ADD district
export const deleteClass = async (data, realmUser) => {
  // GraphQL query to fetch mode analytics as well as the category analytics.
  const deleteClassQuery = gql`
    mutation ($data: ClassQueryInput!) {
      deleteOneClass(query: $data) {
        name
      }
    }
  `;
  const queryVariables = { data: data };

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  //Handle Session Token expired. - Sign out on session token expired.
  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      deleteClassQuery,
      queryVariables,
      headers
    );
    return resp;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const deleteClasses = async (query, realmUser) => {
  // GraphQL query to fetch mode analytics as well as the category analytics.
  const deleteClassesQuery = gql`
    mutation ($data: ClassQueryInput!) {
      deleteManyClasses(query: $data) {
        deletedCount
      }
    }
  `;
  const queryVariables = { data: query };

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  //Handle Session Token expired. - Sign out on session token expired.
  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      deleteClassesQuery,
      queryVariables,
      headers
    );
    return resp;
  } catch (error) {
    console.log(error);
    return error;
  }
};

//DISTRICT SECTION
export const updateDistrict = async (districtName, realmUser, setObject) => {
  // GraphQL query to fetch mode analytics as well as the category analytics.
  const updateDistrictQuery = gql`
    mutation EditDistrict(
      $query: DistrictQueryInput!
      $set: DistrictUpdateInput!
    ) {
      updateOneDistrict(query: $query, set: $set) {
        name
        schools
        superadmins
      }
    }
  `;
  const queryVariables = {
    query: {
      name: districtName,
    },
    set: setObject,
  };
  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      updateDistrictQuery,
      queryVariables,
      headers
    );
    return resp;
  } catch (error) {
    console.log(error);
    return error;
  }
};

// This updates district the safe way -- on a unique ID
export const updateDistrictV2 = async (_id, realmUser, setObject) => {
  // GraphQL query to fetch mode analytics as well as the category analytics.
  const updateDistrictQuery = gql`
    mutation EditDistrict(
      $query: DistrictQueryInput!
      $set: DistrictUpdateInput!
    ) {
      updateOneDistrict(query: $query, set: $set) {
        name
        schools
        superadmins
      }
    }
  `;
  const queryVariables = {
    query: {
      _id: _id,
    },
    set: setObject,
  };
  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      updateDistrictQuery,
      queryVariables,
      headers
    );
    return resp;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const loadDistrict = async (districtName, realmUser) => {
  // GraphQL query to fetch mode analytics as well as the category analytics.
  const getDistrictQuery = gql`
    query ($name: String) {
      district(query: { name: $name }) {
        name
        schools
        superadmins
      }
    }
  `;
  const queryVariables = { name: districtName };
  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      getDistrictQuery,
      queryVariables,
      headers
    );
    return resp;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const loadDistrictFromCleverId = async (cleverId, realmUser) => {
  const getDistrictQuery = gql`
    query ($cleverId: String) {
      district(query: { cleverId: $cleverId }) {
        name
        schools
        superadmins
        cleverId
      }
    }
  `;
  const queryVariables = { cleverId };
  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      getDistrictQuery,
      queryVariables,
      headers
    );
    return resp;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const loadAllDistricts = async (realmUser) => {
  // GraphQL query to fetch all district information.
  const getAllDistrictsQuery = gql`
    query {
      districts {
        _id
        name
        schools
        cleverId
        superadmins
      }
    }
  `;

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };

  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      getAllDistrictsQuery,
      null,
      headers
    );
    return resp;
  } catch (error) {
    console.log(error);
    return error;
  }
};

// student section
export const updateStudentCount = async (user, incrementValue, realmUser) => {
  const currentCount = user.count;
  const newCount = currentCount + incrementValue;
  return await updateProfile(user.email, realmUser, { count: newCount });
};

export const fetchStudentCount = async (userID, realmUser) => {
  const getWellnessByUserIDQuery = gql`
    query GetWellnessDataByUserID($userID: String!) {
      user(query: { userID: $userID }) {
        count
      }
    }
  `;

  const queryVariables = { userID: userID };

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };

  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      getWellnessByUserIDQuery,
      queryVariables,
      headers
    );
    return resp; // Assuming the returned data has this structure.
  } catch (error) {
    console.log(error);
    return error;
  }
};

// Wellness Document Section

export const loadWellnesses = async (data, realmUser) => {
  // GraphQL query to fetch mode analytics as well as the category analytics.
  const getDistrictQuery = gql`
    query loadWellnessData($data: WellnessQueryInput!) {
      wellnesses(limit: 1000000, query: $data, sortBy: DATE_DESC) {
        _id
        userID
        date
        helpful
        emotion
        action
      }
    }
  `;
  const queryVariables = { data: data };
  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      getDistrictQuery,
      queryVariables,
      headers
    );
    return resp;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const insertWellness = async (wellnessData, user, realmUser) => {
  // Including the userID from realmUser into the wellnessData
  const enrichedWellnessData = {
    ...wellnessData,
    userID: user._id,
  };

  const insertWellnessQuery = gql`
    mutation ($data: WellnessInsertInput!) {
      insertOneWellness(data: $data) {
        _id
        action
        date
        emotion
        helpful
        userID
      }
    }
  `;

  // Using enrichedWellnessData instead of the original wellnessData
  const queryVariables = { data: enrichedWellnessData };

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };

  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      insertWellnessQuery,
      queryVariables,
      headers
    );
    return resp;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export async function updateWellnessHelpfulMutation(id, helpful, realmUser) {
  const updateWellnessHelpfulQuery = gql`
    mutation UpdateWellnessHelpful($id: ObjectId!, $helpful: Boolean!) {
      updateOneWellness(query: { _id: $id }, set: { helpful: $helpful }) {
        _id
        helpful
      }
    }
  `;

  const queryVariables = {
    id: id,
    helpful: helpful,
  };

  // Assuming realmUser contains required credentials
  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };

  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      updateWellnessHelpfulQuery,
      queryVariables,
      headers
    );
    return resp;
  } catch (error) {
    console.log(error);
    return error;
  }
}

// DATA TRENDS SECTION
export const getWellnessDataByUserID = async (userID, realmUser) => {
  const getWellnessByUserIDQuery = gql`
    query GetWellnessDataByUserID($userID: String!) {
      wellnesses(query: { userID: $userID }) {
        _id
        action
        date
        emotion
        helpful
      }
    }
  `;

  const queryVariables = { userID: userID };

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };

  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      getWellnessByUserIDQuery,
      queryVariables,
      headers
    );
    return resp.wellnesses; // Assuming the returned data has this structure.
  } catch (error) {
    console.log(error);
    return error;
  }
};

//////////////////////////

export const updateEmotionCount = async (email, realmUser, emotion) => {
  const mutation = gql`
    mutation UpdateEmotionCount($email: String!, $emotionField: String!) {
      updateStudent(query: {email: $email}, inc: { [$emotionField]: 1 }) {
        counts
      }
    }
    `;

  const variables = {
    email: email,
    emotionField: `counts.${emotion}`,
  };

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };

  try {
    const resp = await request(GRAPHQL_ENDPOINT, mutation, variables, headers);
    return resp;
  } catch (error) {
    console.log(error);
    if (error.response.error_code === "InvalidSession") {
      return "InvalidSession";
    }
    return error;
  }
};

export const loadDistricts = async (realmUser) => {
  // GraphQL query to fetch mode analytics as well as the category analytics.
  const getDistrictQuery = gql`
    query {
      districts {
        _id
        name
        schools
        superadmins
        cleverId
      }
    }
  `;
  const queryVariables = {};
  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      getDistrictQuery,
      queryVariables,
      headers
    );
    return resp;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const updateSuperAdmins = async (district, admins, realmUser) => {
  const { _id } = district;
  const updateDistrict = gql`
    mutation ($school: displayName) {
      updateOneDistrict(query: { _id: ${_id} }, set: {superadmins: $superadmins}) {
        name
      }
    }
  `;
};

export const insertDistrict = async (districtData, realmUser) => {
  // GraphQL query to fetch mode analytics as well as the category analytics.
  const insertDistrictQuery = gql`
    mutation ($data: DistrictInsertInput!) {
      insertOneDistrict(data: $data) {
        name
      }
    }
  `;
  const queryVariables = { data: districtData };

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  //Handle Session Token expired. - Sign out on session token expired.
  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      insertDistrictQuery,
      queryVariables,
      headers
    );
    return resp;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const deleteDistrict = async (districtName, realmUser) => {
  // GraphQL query to fetch mode analytics as well as the category analytics.
  const deleteDistrictQuery = gql`
    mutation ($data: DistrictQueryInput!) {
      deleteOneDistrict(query: $data) {
        name
      }
    }
  `;
  const queryVariables = { data: { name: districtName } };

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  //Handle Session Token expired. - Sign out on session token expired.
  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      deleteDistrictQuery,
      queryVariables,
      headers
    );
    return resp;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getDistrictsWhereUserIsSuperadmin = async (email, realmUser) => {
  // Single query to get all districts where user is a superadmin
  const getDistrictsQuery = gql`
    query ($email: String!) {
      districts(query: { superadmins_in: [$email] }) {
        _id
        name
        superadmins
      }
    }
  `;

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };


  try {
    // Get all districts where user is a superadmin in a single query
    const districtsResp = await request(
      GRAPHQL_ENDPOINT,
      getDistrictsQuery,
      { email },
      headers
    );

    // Return the list of districts with required fields
    return districtsResp.districts.map(district => ({
      _id: district._id,
      name: district.name,
      superadmins: district.superadmins
    }));
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getSchoolsWhereUserIsAdmin = async (email, realmUser) => {
  // Single query to get all schools where user is admin
  const getSchoolsQuery = gql`
    query ($email: String!) {
      schools(query: { admins_in: [$email] }) {
        _id
        name
        admins
        district
      }
    }
  `;

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };

  try {
    // Get all schools where user is admin in a single query
    const schoolsResp = await request(
      GRAPHQL_ENDPOINT,
      getSchoolsQuery,
      { email },
      headers
    );

    // Group schools by district
    const districtMap = {};
    
    for (const school of schoolsResp.schools) {
      if (!districtMap[school.district]) {
        districtMap[school.district] = {
          district_name: school.district,
          schools: []
        };
      }
      districtMap[school.district].schools.push(school);
    }

    // Convert map to array
    return Object.values(districtMap);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const updateSchoolAdmins = async (schoolId, admins, realmUser) => {
  const updateSchoolAdminsQuery = gql`
    mutation UpdateSchoolAdmins($query: SchoolQueryInput!, $set: SchoolUpdateInput!) {
      updateOneSchool(query: $query, set: $set) {
        _id
        name
        admins
      }
    }
  `;

  const queryVariables = {
    query: {
      _id: schoolId
    },
    set: {
      admins: admins
    }
  };

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };

  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      updateSchoolAdminsQuery,
      queryVariables,
      headers
    );
    return resp;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getClassesWhereUserIsTeacher = async (email, realmUser) => {
  const getClassesQuery = gql`
    query GetTeacherClasses($email: String!) {
      classes(query: { teachers_in: [$email] }) {
        _id
        name
        school
        district
        teachers
        students
      }
    }
  `;

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };

  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      getClassesQuery,
      { email },
      headers
    );

    // Group classes by district
    const districtMap = {};
    
    for (const classData of resp.classes) {
      if (!districtMap[classData.district]) {
        districtMap[classData.district] = {
          district_name: classData.district,
          classes: []
        };
      }
      districtMap[classData.district].classes.push(classData);
    }

    // Convert map to array
    return Object.values(districtMap);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const updateClassTeachers = async (classId, teachers, realmUser) => {
  const updateClassTeachersQuery = gql`
    mutation UpdateClassTeachers($query: ClassQueryInput!, $set: ClassUpdateInput!) {
      updateOneClass(query: $query, set: $set) {
        _id
        name
        teachers
      }
    }
  `;

  const queryVariables = {
    query: {
      _id: classId
    },
    set: {
      teachers: teachers
    }
  };

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };

  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      updateClassTeachersQuery,
      queryVariables,
      headers
    );
    return resp;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getClassesWhereUserIsStudent = async (email, realmUser) => {
  const getClassesQuery = gql`
    query GetStudentClasses($email: String!) {
      classes(query: { students_in: [$email] }) {
        _id
        name
        school
        district
        students
      }
    }
  `;

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };

  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      getClassesQuery,
      { email },
      headers
    );

    // Group classes by district
    const districtMap = {};

    for (const classData of resp.classes) {
      if (!districtMap[classData.district]) {
        districtMap[classData.district] = {
          district_name: classData.district,
          classes: []
        };
      }
      districtMap[classData.district].classes.push(classData);
    }

    // Convert map to array
    return Object.values(districtMap);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const updateClassStudents = async (classId, students, realmUser) => {
  const updateClassStudentsQuery = gql`
    mutation UpdateClassStudents($query: ClassQueryInput!, $set: ClassUpdateInput!) {
      updateOneClass(query: $query, set: $set) {
        _id
        name
        students
      }
    }
  `;

  const queryVariables = {
    query: {
      _id: classId
    },
    set: {
      students: students
    }
  };

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };

  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      updateClassStudentsQuery,
      queryVariables,
      headers
    );
    return resp;
  } catch (error) {
    console.log(error);
    return error;
  }
};
export const getTeacherClassesOutsideSchool = async (email, realmUser, currentSchoolId) => {
  const getClassesQuery = gql`
    query GetTeacherClassesOutsideSchool($email: String!, $currentSchoolId: [String]) {
      classes(query: { teachers_in: [$email], school_nin: $currentSchoolId }) {
        _id
        name
        school
        district
        teachers
        students
      }
    }
  `;

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };

  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      getClassesQuery,
      { email, currentSchoolId },
      headers
    );

    const districtMap = {};
    for (const classData of resp.classes) {
      if (!districtMap[classData.district]) {
        districtMap[classData.district] = {
          district_name: classData.district,
          classes: []
        };
      }
      districtMap[classData.district].classes.push(classData);
    }

    return Object.values(districtMap);
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getStudentClassesOutsideSchool = async (email, realmUser, currentSchoolId) => {
  const getClassesQuery = gql`
    query GetStudentClassesOutsideSchool($email: String!, $currentSchoolId: [String]) {
      classes(query: { students_in: [$email], school_nin: $currentSchoolId }) {
        _id
        name
        school
        district
        teachers
        students
      }
    }
  `;

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };

  try {
    const resp = await request(
      GRAPHQL_ENDPOINT,
      getClassesQuery,
      { email, currentSchoolId },
      headers
    );

    const districtMap = {};
    for (const classData of resp.classes) {
      if (!districtMap[classData.district]) {
        districtMap[classData.district] = {
          district_name: classData.district,
          classes: []
        };
      }
      districtMap[classData.district].classes.push(classData);
    }

    return Object.values(districtMap);
  } catch (error) {
    console.error(error);
    return error;
  }
};