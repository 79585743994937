import React, { useState, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import { Error } from "@mui/icons-material";

const messages = [
  "Updating...",
  "Just a moment more...",
  "Hang tight...",
];

export const LoadingOverlay = () => {
  const [messageIndex, setMessageIndex] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const messageInterval = setInterval(() => {
      setMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
    }, 15000); // Change message every 15 seconds

    const errorTimeout = setTimeout(() => {
      setErrorMessage("An error may have occurred. Please refresh the page.");
    }, 60000); // Show error message after 1 minute

    return () => {
      clearInterval(messageInterval);
      clearTimeout(errorTimeout);
    };
  }, []);

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        zIndex: 9999,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        animation: "backgroundAnimation 10s infinite alternate",
      }}
    >
      {errorMessage === "" ? (
        <CircularProgress
          style={{
            height: "80px",
            width: "80px",
            color: "#ffffff",
          }}
        />
      ) : (
        <Error
          style={{
            height: "80px",
            width: "80px",
            color: "#ffffff",
          }}
        ></Error>
      )}
      <p
        style={{
          color: "#ffffff",
          fontSize: "18px",
          marginTop: "20px",
          fontFamily: "Arial, sans-serif",
        }}
      >
        {errorMessage || messages[messageIndex]}
      </p>
      <style>{`
        @keyframes backgroundAnimation {
          0% { background-color: rgba(0, 0, 0, 0.7); }
          100% { background-color: rgba(0, 0, 0, 0.9); }
        }
      `}</style>
    </div>
  );
};
