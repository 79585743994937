import {
    getDistrictsWhereUserIsSuperadmin,
    updateDistrictV2,
    getSchoolsWhereUserIsAdmin,
    updateSchoolAdmins,
    getClassesWhereUserIsTeacher,
    updateClassTeachers,
    getClassesWhereUserIsStudent,
    updateClassStudents,
    getStudentClassesOutsideSchool,
    getTeacherClassesOutsideSchool
  } from "../../../realm/graphqlQueries";

export const clearExistingProfiles = async (email, realmUser, currentProfile, currentSchoolId) => {

  if (currentProfile === "superadmin" || currentProfile === "admin") {
    // 1. Clear existing superadmins
    const districtsBySuperadmin = await getDistrictsWhereUserIsSuperadmin(email, realmUser);
    for (const district of districtsBySuperadmin) {
      district.superadmins = district.superadmins.filter(superadmin => superadmin !== email);
      await updateDistrictV2(district._id, realmUser, { superadmins: district.superadmins });
    }

    // 2. Clear existing admins
    const schoolsByDistrict = await getSchoolsWhereUserIsAdmin(email, realmUser);
    schoolsByDistrict.forEach(district => {
      district.schools.forEach(async school => {
        school.admins = school.admins.filter(admin => admin !== email);
        await updateSchoolAdmins(school._id, school.admins, realmUser);
      });
    });

    // 3. Clear existing teachers
    const classesByDistrict = await getClassesWhereUserIsTeacher(email, realmUser);
    classesByDistrict.forEach(async district => {
      district.classes.forEach(async classData => {
        classData.teachers = classData.teachers.filter(teacher => teacher !== email);
        await updateClassTeachers(classData._id, classData.teachers, realmUser);
      });
    });

    // 4. Remove Email from Students List
    const classesByStudent = await getClassesWhereUserIsStudent(email, realmUser);
    classesByStudent.forEach(async district => {
      district.classes.forEach(async classData => {
        if (classData.students.includes(email)) {
          classData.students = classData.students.filter(student => student !== email);
          await updateClassStudents(classData._id, classData.students, realmUser);
        }
      });
    });
  } else if (currentProfile === "teacher") {
    // 1. Clear existing superadmins
    const districtsBySuperadmin = await getDistrictsWhereUserIsSuperadmin(email, realmUser);
    for (const district of districtsBySuperadmin) {
      district.superadmins = district.superadmins.filter(superadmin => superadmin !== email);
      await updateDistrictV2(district._id, realmUser, { superadmins: district.superadmins });
    }

    // 2. Clear existing admins
    const schoolsByDistrict = await getSchoolsWhereUserIsAdmin(email, realmUser);
    schoolsByDistrict.forEach(district => {
      district.schools.forEach(async school => {
        school.admins = school.admins.filter(admin => admin !== email);
        await updateSchoolAdmins(school._id, school.admins, realmUser);
      });
    });

    // 3. Clear existing teachers (outside current school only)
    // const classesByTeacher = await getTeacherClassesOutsideSchool(email, realmUser, currentSchoolId);
    // for (const classData of classesByTeacher) {
    //   if (classData.teachers?.includes(email)) {
    //     classData.teachers = classData.teachers.filter(teacher => teacher !== email);
    //     await updateClassTeachers(classData._id, classData.teachers, realmUser);
    //   }
    // }

    // 4. Remove Email from Students List
    const classesByStudent = await getClassesWhereUserIsStudent(email, realmUser);
    classesByStudent.forEach(async district => {
      district.classes.forEach(async classData => {
        if (classData.students.includes(email)) {
          classData.students = classData.students.filter(student => student !== email);
          await updateClassStudents(classData._id, classData.students, realmUser);
        }
      });
    });
  } else if (currentProfile === "student") {
    // 1. Clear existing superadmins
    const districtsBySuperadmin = await getDistrictsWhereUserIsSuperadmin(email, realmUser);
    for (const district of districtsBySuperadmin) {
      district.superadmins = district.superadmins.filter(superadmin => superadmin !== email);
      await updateDistrictV2(district._id, realmUser, { superadmins: district.superadmins });
    }

    // 2. Clear existing admins
    const schoolsByDistrict = await getSchoolsWhereUserIsAdmin(email, realmUser);
    schoolsByDistrict.forEach(district => {
      district.schools.forEach(async school => {
        school.admins = school.admins.filter(admin => admin !== email);
        await updateSchoolAdmins(school._id, school.admins, realmUser);
      });
    });

    // 3. Clear existing teachers
    const classesByDistrict = await getClassesWhereUserIsTeacher(email, realmUser);
    classesByDistrict.forEach(async district => {
      district.classes.forEach(async classData => {
        classData.teachers = classData.teachers.filter(teacher => teacher !== email);
        await updateClassTeachers(classData._id, classData.teachers, realmUser);
      });
    });

    // 4. Clear existing students (outside current school only)
    // const classesByStudent = await getStudentClassesOutsideSchool(email, realmUser, currentSchoolId);
    // for (const classData of classesByStudent) {
    //   if (classData.students?.includes(email)) {
    //     classData.students = classData.students.filter(student => student !== email);
    //     await updateClassStudents(classData._id, classData.students, realmUser);
    //   }
    // }
  } 
}
