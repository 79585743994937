import React, { useContext, useEffect, useState, useRef } from "react";
import { authentication } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { Context } from "../../components/Store";
import "./HomeScreen.css";
import { Context3 } from "../../components/Timer";
import { Context2 } from "../../components/Audio";
import { loadProfile } from "../../realm/graphqlQueries";
import { UserContext } from "../../realm/user.context";
import { FaVolumeUp, FaVolumeMute } from "react-icons/fa";
import { useLocation } from "react-router-dom";

const WEEK_STREAKS = [
  2, 3, 5, 10, 20, 30, 40, 52, 60, 70, 80, 90, 104, 156, 208, 260, 312, 364,
  416, 468, 520,
];
const DAY_STREAKS = [
  3, 5, 7, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 125, 150, 175, 200, 250,
  300, 365, 500, 730, 1095, 1460, 1825,
];

const HomeScreen = (props) => {
  const [count, setCount] = useState(0);
  const [studentName, setStudentName] = useState("");
  const navigate = useNavigate();
  //const [count, setCount] = useState(0);
  const [wellnessDataPoint, setWellnessDataPoint] = useContext(Context);
  const [time, setTime] = useContext(Context3);
  const [playing, setPlaying] = useContext(Context2);
  const [loadingData, setLoadingData] = useState(true);

  const usingClever = useRef(authentication.currentUser === null);

  const { realmUser, logInRealmUser, logOutRealmUser, getValidAccessToken } =
    useContext(UserContext);

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  };

  async function getUserInfo() {
    // load data from mongodb
    const email = props.user.user.email
    const name =  props.user.user.displayName ?? authentication.currentUser.displayName ?? realmUser.profile.name ?? props.user.user.email

    const realmResp = await loadProfile(email, realmUser);
    setStudentName(name.split(" ")[0]);
    setCount(realmResp.user.count);
  }

  useEffect(() => {
    getUserInfo().finally(() => {
      setLoadingData(false);
    });
  }, []);

  useEffect(() => {
    // get rid of the clever code in the url if necessary. if reloaded with the
    // already-used code, it'll likely cause a frontend error
    const splitRef = window.location.href.split("?");
    if (splitRef.length > 1) {
      navigate("/");
    }
  }, []);

  function clearDataPointAndNav() {
    setWellnessDataPoint(initialState);
    setPlaying(false);
    navigate("/deepbreathe");
  }
  const LittleCastles = () => {
    const numCastles = Math.floor(props.user.user.count / 60);
    let arr = [];
    for (let i = 0; i < numCastles; i++) {
      arr.push(i);
    }
    return (
      <div className="littlecastlerow">
        {arr.map((count) => (
          <img
            className="littlecastle"
            src={"../images/Castle_Gifs/Castle(60).gif"}
            alt="loading littl..."
            loading="lazy"
          />
        ))}
      </div>
    );
  };

  const CastleDisplay = (propsPassed) => {
    return (
      <div className="buildcastle">
        {!loadingData && <div className="welcome">Welcome, {studentName}!</div>}
        {props.user.user.count !== null && props.user.user.count % 60 === 0 ? (
          <img
            className="castle"
            src={"../images/Castle_Gifs/Castle(60).gif"}
            alt="loading larger..."
            loading="lazy"
          />
        ) : (
          <img
            className="castlebigger"
            src={
              "../images/Castle_Gifs/Castle(" +
              (props.user.user.count % 60) +
              ").gif"
            }
            alt="loading..."
            loading="lazy"
          />
        )}
        {(props.user.user.count === null || count === 0) && (
          <h1 className="hometext">
            Time to get started on your wellness journey!
          </h1>
        )}
        {count > 0 && (
          <h1 className="hometext">
            You have practiced wellness{" "}
            {numberWithCommas(props.user.user.count)} {props.user.user.count === 1 ? 'time' : 'times'}! Great work!
          </h1>
        )}
        <button onClick={clearDataPointAndNav} className="wellnessButtons">
          START
        </button>
        {props.user.user.count > 60 && <LittleCastles></LittleCastles>}
      </div>
    );
  };

  const MaxBadge = (props) => {
    if (props.badges) {
      for (let badge in props.badges) {
        if (props.badges[badge] > 0) {
          let badgeFileName = `${badge.charAt(0).toUpperCase()}BadgeColor.png`;
          return (
            <div className="homePageAchievementImageItem">
              <img
                src={`../images/badges/color/${badgeFileName}`}
                className="myAchievementsSideIcon"
                id="homePageBadge"
              />
              <p className="homePageAchievementImageLabel">
                {badge}{" "}
                {props.badges[badge] > 1 ? props.badges[badge] + " x" : ""}
              </p>
            </div>
          );
        }
      }
    }
    return (
      <div className="homePageAchievementImageItem">
        <img
          src="../images/badges/color/BlankBadgeTrophyColor.png"
          className="homePageAchievementDisplayImage"
          id="homePageBadge"
        ></img>
        <p className="homePageAchievementImageLabel">
          ...
        </p>
      </div>
    );
  };

  const MaxStreak = (props) => {
    const isWeekStreak = props.maxWeekStreak !== undefined;

    // Grabs the nearest streak value that matches one of the images
    const getNearestStreakValue = () => {
      const currMax = isWeekStreak ? props.maxWeekStreak : props.maxDayStreak;

      const streak_arr = isWeekStreak ? WEEK_STREAKS : DAY_STREAKS;
      if (currMax < streak_arr[0]) {
        return 0;
      }

      // Round down to the nearest streak value associated with
      // the type
      for (let i = 1; i < streak_arr.length; i++) {
        const streakVal = streak_arr[i];
        if (currMax === streakVal) {
          return streakVal;
        } else if (currMax < streakVal) {
          return streak_arr[i - 1];
        }
      }
      return streak_arr[streak_arr.length - 1];
    };

    const nearestStreakVal = getNearestStreakValue();
    if (isWeekStreak && nearestStreakVal > 0) {
      return (
        <div className="homePageAchievementImageItem">
          <img
            className="homePageAchievementDisplayImage"
            src={`../images/streaks/weekStreaks/Color/${nearestStreakVal}WeeksColor.png`}
            id="homePageStreak"
          />
          <p className="homePageAchievementImageLabel">
            Max Week Streak: {props.maxWeekStreak}
          </p>
        </div>
      );
    } else if (!isWeekStreak && nearestStreakVal > 0) {
      return (
        <div className="homePageAchievementImageItem">
          <img
            className="homePageAchievementDisplayImage"
            src={`../images/streaks/dayStreaks/Color/${nearestStreakVal}DaysColor.png`}
            id="homePageStreak"
          />
          <p className="homePageAchievementImageLabel">
            Max Day Streak: {props.maxDayStreak}
          </p>
        </div>
      );
    }
    return (
      <div className="homePageAchievementImageItem">
        <img
          className="homePageAchievementDisplayImage"
          src={`../images/streaks/genericStreaks/BlankStreakBluecolor.png`}
          id="homePageStreak"
        />
        <p className="homePageAchievementImageLabel">
          ...
        </p>
      </div>
    );
  };

  const CoinCount = (props) => {
    return (
      <div className="homePageAchievementImageItem">
        <img
          className="homePageAchievementDisplayImage"
          src="../images/coin front.png"
          id="homePageCoin"
        ></img>
        <p className="homePageAchievementImageLabel">
          {props.coins && props.coins > 0
            ? `${props.coins} coins`
            : "..."}
        </p>
      </div>
    );
  };

  const location = useLocation();
  async function changeAudio(audio) {
    document.cookie = `audio=${audio}; path=/;`;
    if (location.pathname === "/deepbreathe") {
      navigate("/deepbreathecopy");
    } else {
      navigate(location.pathname);
    }
    setPlaying(audio);
  }

  const DefaultHomeScreenElement = (passedProps) => {
    return (
      <div>
        {/* <h1 className="welcomeMessageTop">
          Welcome,{" "}
          {props.user.user.displayName
            ? props.user.user.displayName
            : props.user.user.email}
          !
        </h1> */}
        <div className="homepageTopDiv">
          <div className="blueBackgroundSubtitleHome">
            <h3 className="blueBackgroundSubtitleHeaderHome">
            Welcome,{" "}
          {studentName}
          !

            </h3>
            <h3 className="blueBackgroundSubtitleHeaderHome2">
            Build wellness in your heart and mind and your actions will change the world.{" "}
            </h3>
          </div>
          <div id="homepageTopDivBody">
            <img
              id="heartMindWellnessGif"
              src="../images/Heart_Mind_World_123Wellness_GIF/123WellnessGIF-2024.gif"
            ></img>
            
            <div className="volume-home">
              <button
                onClick={clearDataPointAndNav}
                id="homepageTopDivWellnessButton"
              >
                START
              </button>
              {playing === true ? (
                  <div className="volume-up" onClick={() => changeAudio(false)}>
                    <div className="volume-home-icon">
                      <FaVolumeUp></FaVolumeUp>
                    </div>
                  </div>
                ) : (
                  <div className="volume-mute" onClick={() => changeAudio(true)}>
                    <div className="volume-home-icon">
                      <FaVolumeMute></FaVolumeMute>
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
        <div className="homepageBottomDiv">
          <h2 id="yourAchievementsHeader">Your Achievements</h2>
          {(props.user.user.count === null || props.user.user.count === 0) && (
            <p id="practiceEncouragementParagraph">
              Start practicing wellness and watch your achievements grow!{" "}
            </p>
          )}
          {props.user.user.count > 0 && (
            <h1 id="practiceEncouragementParagraph">
              <strong>Congratulations,</strong> you've practiced wellness{" "}
              <strong>{numberWithCommas(props.user.user.count)} {props.user.user.count === 1 ? 'time' : 'times'}!</strong>
            </h1>
          )}
          <div id="achievementDisplayGrid">
            <CoinCount coins={props.user.user.coins}></CoinCount>
            <MaxStreak
              maxWeekStreak={props.user.user.maxWeekStreak}
            ></MaxStreak>
            <MaxBadge badges={props.user.user.badges}></MaxBadge>
          </div>
        </div>
      </div>
    );
  };

  const ActiveProgressTracker = () => {
    if (props.user.user.usesCastle) {
      return <CastleDisplay propsPassed={props}></CastleDisplay>;
    } else {
      return (
        <DefaultHomeScreenElement
          passedProps={props}
        ></DefaultHomeScreenElement>
      );
    }
  };

  return (
    <div className="outermostrow">
      {/* conditionally render the castle if that's their selected preference */}
      <ActiveProgressTracker props={props}></ActiveProgressTracker>
    </div>
  );
};
const initialState = {
  action: "",
  emotion: "",
  date: "",
  helpful: false,
};
export default HomeScreen;
