import "./StudentsScreen.css";
import { UserContext } from "../../../realm/user.context";
import ReactDataSheet from "react-datasheet";
import React, { useState, useContext } from "react";
import "react-toastify/dist/ReactToastify.css";
import "./toast-overwrites.css";
import {
  insertProfiles,
} from "../../../realm/graphqlQueries";
import Spacer from "../../../components/Spacer";
import CustomEditor from "../../../components/CustomEditor";
import _ from 'lodash';
import UserGrid from "./UserGrid";

const MAX_GRID_ROWS = 250;
const STARTING_STUDENT_GRID = [
  [
    { readOnly: true, value: "", width: "20vw" },
    { value: "Student Emails", width: "30vw", readOnly: true },
  ],
].concat(
  _.range(1, MAX_GRID_ROWS * 4 + 1).map((id) => [
    { readOnly: true, value: `Student ${id}` },
    { readOnly: false, value: "" },
  ])
);

const STARTING_TEACHER_GRID = [
  [
    { readOnly: true, value: "", width: "20vw" },
    { value: "Teacher Emails", width: "30vw", readOnly: true },
  ],
].concat(
  _.range(1, MAX_GRID_ROWS + 1).map((id) => [
    { readOnly: true, value: `Teacher ${id}` },
    { readOnly: false, value: "" },
  ])
);

const AddPeople = ({
  school,
  refresh,
  onMobile,
  allStudents,
  allTeachers,
  addingPeople,
  setAddingPeople,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [hoveringSubmit, setHoveringSubmit] = useState(false);
  const { realmUser } = useContext(UserContext);
  const isTeacher = addingPeople === "teacher";

  // user form stuff
  const [studentGrid, setStudentGrid] = useState(STARTING_STUDENT_GRID);
  const [teacherGrid, setTeacherGrid] = useState(STARTING_TEACHER_GRID);
  const getUsersToSubmit = () => {
    if (isTeacher) {
      const teachersToAdd = [];
      const teachers = teacherGrid.slice(1);
      for (const item of teachers) {
        if (item[1].value !== "") {
          teachersToAdd.push(item[1].value);
        }
      }
      return teachersToAdd;
    } else {
      const studentsToAdd = [];
      const students = studentGrid.slice(1);
      for (const item of students) {
        if (item[1].value !== "") {
          studentsToAdd.push(item[1].value);
        }
      }
      return studentsToAdd;
    }
  };
  const canSubmit = addingPeople && getUsersToSubmit().length > 0;

  if (!school) return <></>;

  const filterPeople = () => {
    const emails = getUsersToSubmit().filter((u) => {
      if  (/^[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/) {
        if (isTeacher) {
          return allTeachers.find((item) => item.email === u) ? false : true;
        }
        return allStudents.find((item) => item.email === u) ? false : true;
      }
      return false;
    });

    return emails.map((e) => {
      return {
        count: 0,
        email: e,
        class: [],
        dataUse: true,
        school: school.name,
        agreedToTerms: false,
        schools: [school.name],
        district: school.district,
        wellnesstimer: "30 seconds",
        user: isTeacher ? "teacher" : "student",
      };
    });
  };

  const handleSaveChanges = async () => {
    if (!submitting && canSubmit !== "") {
      setSubmitting(true);
      const filtered = filterPeople();
      if (filtered.length > 0) {
        await insertProfiles(filterPeople(), realmUser);
        await refresh();
      }
      if (isTeacher) {
        setTeacherGrid(STARTING_TEACHER_GRID);
      } else {
        setStudentGrid(STARTING_STUDENT_GRID);
      }
      setSubmitting(false);
      setAddingPeople(false);
    }
  }

  return (
    <div
      onClick={() => setAddingPeople(false)}
      style={{
        top: 0,
        left: 0,
        display: "flex",
        position: "fixed",
        alignItems: "center",
        justifyContent: "center",
        transition: "opacity 0.5s",
        opacity: addingPeople ? 1 : 0,
        width: addingPeople ? "100vw" : 0,
        height: addingPeople ? "100vh" : 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
      }}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        style={{
          width: "50%",
          display: "flex",
          padding: "2.5vmin",
          borderRadius: "5px",
          alignItems: "center",
          flexDirection: "column",
          backgroundColor: "#fff",
          marginLeft: onMobile ? 0 : "20vw",
        }}
      >
        <p
          style={{
            margin: 0,
            width: "100%",
            color: "#4cc68d",
            fontSize: "5vmin",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          Add {isTeacher ? "Teacher" : "Student"}s
        </p>
        <Spacer height="2.5vmin" />
        <div className="spreadsheetrow">
          <div className="limitspreadsheet">
          {addingPeople && (
            <UserGrid
              initialData={isTeacher ? teacherGrid : studentGrid} // Use the appropriate grid based on the role
              onSaveChanges={handleSaveChanges} // Assuming this function handles saving data
            />
          )}
          </div>
        </div>

        <Spacer height="2.5vmin" />
        {/* <div
          onMouseEnter={() => setHoveringSubmit(true)}
          onMouseLeave={() => setHoveringSubmit(false)}
          onClick={async () => {
            if (!submitting && canSubmit !== "") {
              setSubmitting(true);
              const filtered = filterPeople();
              if (filtered.length > 0) {
                await insertProfiles(filterPeople(), realmUser);
                await refresh();
              }
              if (isTeacher) {
                setTeacherGrid(STARTING_TEACHER_GRID);
              } else {
                setStudentGrid(STARTING_STUDENT_GRID);
              }
              setSubmitting(false);
              setAddingPeople(false);
            }
          }}
          style={{
            color: "#fff",
            padding: "1vmin",
            fontSize: "3vmin",
            borderRadius: "5px",
            backgroundColor: "#4cc68d",
            filter: `grayscale(${canSubmit ? 0 : 1})`,
            cursor: hoveringSubmit && canSubmit ? "pointer" : "default",
          }}
        >
          Submit
        </div> */}
      </div>
    </div>
  );
};

export default AddPeople;