import "./StudentsScreen.css";
import React, { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import "./toast-overwrites.css";
import Spacer from "../../../components/Spacer";

const SchoolButton = ({
  schoolData,
  onMobile,
  selectedSchool,
  setSelectedSchool,
}) => {
  const [hovering, setHovering] = useState(false);
  const nonCleverSchool = !schoolData.cleverId;
  const selected = selectedSchool === schoolData.name;
  const highlightedColor = nonCleverSchool ? "#4cc68d" : "#1464ff";
  return (
    <div
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      onClick={() => setSelectedSchool(selected ? undefined : schoolData.name)}
      style={{
        padding: "2vmin",
        textAlign: "center",
        borderRadius: "5px",
        borderWidth: "2.5px",
        borderStyle: "solid",
        marginBottom: "2.5vmin",
        borderColor: highlightedColor,
        transition: "background-color 0.5s",
        cursor: hovering ? "pointer" : "default",
        backgroundColor: selected || hovering ? highlightedColor : "#fff",
      }}
    >
      <p
        style={{
          margin: 0,
          width: "25vw",
          fontSize: "3vmin",
          fontWeight: "bold",
          textAlign: "center",
          color: selected || hovering ? "#fff" : highlightedColor,
        }}
      >
        {schoolData.name}
      </p>
      <Spacer height="2vmin" />
      <p
        style={{
          margin: 0,
          fontSize: "3vmin",
          color: selected || hovering ? "#fff" : highlightedColor,
        }}
      >
        {schoolData.classes.length === 0
          ? "No classes"
          : `${schoolData.classes.length} classes`}
      </p>
      <Spacer height="2vmin" />
      <p
        style={{
          margin: 0,
          fontSize: "3vmin",
          color: selected || hovering ? "#fff" : highlightedColor,
        }}
      >
        {schoolData.admins.length === 0
          ? "No admins"
          : `${schoolData.admins.length} admin${
              schoolData.admins.length === 1 ? "" : "s"
            }`}
      </p>

      {selected && (
        <p
          style={{
            color: highlightedColor,
            fontWeight: "bold",
            paddingTop: "2vmin",
            position: "absolute",
            marginTop: "2.5px",
            marginBottom: 0,
            fontSize: "2.5vmin",
            transform: `translate(${onMobile ? "-8vmin" : "-2vmin"} )`,
          }}
        >
          Click here to view more schools
        </p>
      )}
    </div>
  );
};

export default SchoolButton;