import { useState } from "react";

const AddClassButton = ({ setCreatingClass }) => {
  const [hovering, setHovering] = useState(false);
  return (
    <>
      <div
        onClick={() => setCreatingClass(true)}
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
        style={{
          padding: "2vmin",
          fontSize: "3vmin",
          borderRadius: "5px",
          borderWidth: "2.5px",
          borderStyle: "solid",
          borderColor: "#4cc68d",
          color: hovering ? "#fff" : "#4cc68d",
          cursor: hovering ? "pointer" : "default",
          backgroundColor: hovering ? "#4cc68d" : "#fff",
          transition: "color 0.5s, background-color 0.5s",
        }}
      >
        Create New Class
      </div>
    </>
  );
};

export default AddClassButton;