import React from "react";
import "./CongratulationsBanner.css";

const CongratulationsBanner = ({ state, userRole, count, time }) => {
  const formatNumber = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const renderBannerContent = () => {
    if (count !== undefined) {
      const formattedCount = formatNumber(count);
      return (
        <>
          Congratulations, you practiced wellness{" "}
          <strong>{formattedCount}</strong> {Number(formattedCount) === 1 ? 'time' : 'times'} in the last{" "}
          <strong>{time}</strong>!
        </>
      );
    }

    const formattedCounter = formatNumber(state.counter);
    const studentName =
      state.chosenStudent && state.studentEmailToNameMap[state.chosenStudent]
        ? state.studentEmailToNameMap[state.chosenStudent]
        : state.chosenStudent;

    switch (userRole) {
      case "123wellness":
        return (
          <>
            Congratulations, <strong>{state.chosenSchool} </strong>in{" "}
            <strong>{state.chosenDistrict},</strong> practiced wellness{" "}
            <strong>{formattedCounter}</strong> {Number(formattedCounter) === 1 ? 'time' : 'times'} in the last{" "}
            <strong>{state.chosenTime}!</strong>
          </>
        );
      case "superadmin":
        return (
          <>
            Congratulations, <strong>{studentName}</strong> in{" "}
            <strong>{state.chosenClass}</strong>, in{" "}
            <strong>{state.chosenSchool},</strong> practiced wellness{" "}
            <strong>{formattedCounter}</strong> {Number(formattedCounter) === 1 ? 'time' : 'times'} in the last{" "}
            <strong>{state.chosenTime}!</strong>
          </>
        );
      case "admin":
      case "teacher":
        return (
          <>
            Congratulations, <strong>{studentName}</strong> in{" "}
            <strong>{state.chosenClass},</strong> practiced wellness{" "}
            <strong>{formattedCounter}</strong> {Number(formattedCounter) === 1 ? 'time' : 'times'} in the last{" "}
            <strong>{state.chosenTime}!</strong>
          </>
        );
      default:
        return null;
    }
  };

  return shouldRenderBanner(count, state, userRole) ? (
    <div className="container">
      <div className="congratulations-banner">{renderBannerContent()}</div>
    </div>
  ) : null;
};

export default CongratulationsBanner;

export const shouldRenderBanner = (count, state, userRole) => {
  if (count !== undefined) return true;

  switch (userRole) {
    case "123wellness":
      return state.chosenDistrict !== "Choose District";
    case "superadmin":
      return state.chosenSchool !== "Choose School";
    case "admin":
    case "teacher":
      return state.chosenClass !== "Choose Class";
    default:
      return false;
  }
};
