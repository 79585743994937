import _ from "lodash";
import "./StudentsScreen.css";
import ReactDataSheet from "react-datasheet";
import React, { useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import "./toast-overwrites.css";
import {
  insertProfiles,
  updateProfile,
  insertDistrict,
  loadProfile,
  loadDistricts,
} from "../../../realm/graphqlQueries";
import Spacer from "../../../components/Spacer";
import { LoadingOverlay } from "../../../components/LoadingOverlay";
import CustomEditor from "../../../components/CustomEditor";
import { toast } from "react-toastify";
import UserGrid from "./UserGrid";
import { clearExistingProfiles } from '../utils/utils';

const AddDistrictButton = ({ refresh, onMobile, realmUser }) => {
  const [inputVal, setInputVal] = useState("");
  const [hovering, setHovering] = useState(false);
  const [hoveringSubmit, setHoveringSubmit] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [addingDistrict, setAddingDistrict] = useState(false);
  const [adminGrid, setAdminGrid] = useState([]);
  let startingAdminGrid = []
  let i = 0
  while(i < 5){
    startingAdminGrid.push({email: ''})
    i++
  }

  useEffect(() => {
    setAdminGrid(startingAdminGrid);
  }, []);

  let canSubmit = inputVal !== "";
  
  async function addUser(attributes) {
    let nAttributes = attributes;
    const profile = await loadProfile(attributes.email, realmUser);
    if (profile.user === null) {
      //console.log("inserting user ", attributes);
      await insertProfiles([attributes], realmUser);
      return;
    } else if (
      attributes.classes !== undefined &&
      attributes.classes !== null
    ) {
      //Classes are added to a list, other attributes simply overwrite
      let nClasses = [...Set(profile.user.class.concat(attributes.classes))];
      nAttributes.classes = nClasses;
    }
    updateProfile(attributes.email, realmUser, attributes);
  }

  const handleSaveChanges = async (updatedData) => {
    try {
      if (!submitting && inputVal !== "") {
        setSubmitting(true);
        const newEmails = updatedData
          .map((row) => row.email.trim())
          .filter((email) => email !== "");

        for (const email of newEmails) {
          await clearExistingProfiles(email, realmUser, "superadmin", null);

          let userDoc = {
            email: email,
            user: "superadmin",
            district: inputVal,
            count: 0,
            dataUse: false,
            class: [],
            school: "",
            schools: [],
            wellnesstimer: "30 seconds",
          };
          await addUser(userDoc);
        }
        await insertDistrict({ schools: [], superadmins: newEmails, name: inputVal }, realmUser);
        await refresh();
        setAddingDistrict(false);
        setSubmitting(false);
      }
    } catch (error) {
      console.error("Save to DB error", error);
    }
  };

  if (submitting) return <LoadingOverlay></LoadingOverlay>;
  return (
    <>
      <p
        onClick={() => setAddingDistrict(true)}
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
        style={{
          margin: 0,
          padding: "1vmin",
          cursor: "pointer",
          fontSize: "3vmin",
          fontWeight: "bold",
          borderRadius: "5px",
          borderWidth: "2.5px",
          borderStyle: "solid",
          borderColor: "#4cc68d",
          color: hovering ? "#FFF" : "#4cc68d",
          backgroundColor: hovering ? "#4cc68d" : "#FFF",
          transition: "color 0.25s, background-color 0.25s",
        }}
      >
        Create a new district
      </p>
      <Spacer height={"4vmin"} />

      {/* 
        This is a part of the screen, but doesn't actually take up space.
        TODO: add styling to buttons and stuff
      */}
      <div
        style={{
          top: 0,
          left: 0,
          height: "100vh",
          position: "fixed",
          transition: "width 0.5s",
          backgroundColor: "#4cc68d",
          zIndex: addingDistrict ? 2 : -1,
          width: addingDistrict ? "100vw" : "0vw",

          display: "flex",
          flexDirection: "column",
        }}
      >
        <p
          onClick={() => {
            setAddingDistrict(false);
          }}
          style={{
            margin: 0,
            top: "2vmin",
            left: "2vmin",
            color: "#FFF",
            padding: "1vmin",
            cursor: "pointer",
            borderRadius: "5px",
            borderColor: "#FFF",
            borderWidth: "2.5px",
            borderStyle: "solid",
            position: "absolute",
          }}
        >
          Go Back
        </p>
        <p
          className="SSV2Title"
          style={{
            alignSelf: "center",
            color: "#FFF",
            transitionDelay: "1s",
            transition: "opacity 0.5s",
            opacity: addingDistrict ? 1 : 0,
          }}
        >
          District Name
        </p>
        <input
          type="text"
          style={{
            width: "30vmax",
            color: "#4cc68d",
            padding: "2vmin",
            // fontSize: "2vmin",
            fontSize: `${onMobile ? "3.5vmin" : "2vmin"}`,
            fontWeight: "bold",
            alignSelf: "center",
            borderRadius: "5px",
            textAlign: "center",
            borderColor: "#4cc68d",
            transitionDelay: "1s",
            transition: "opacity 0.5s",
            opacity: addingDistrict ? 1 : 0,
          }}
          onChange={(e) => setInputVal(e.target.value)}
        />
        <Spacer height="5vmin" />
        <p
          className="SSV2Title"
          style={{
            alignSelf: "center",
            color: "#FFF",
            marginBottom: "0",
            transitionDelay: "1s",
            transition: "opacity 0.5s",
            opacity: addingDistrict ? 1 : 0,
          }}
        >
          Superadmins (emails only)
        </p>
        <div
          className="spreadsheetrow"
          style={{
            alignSelf: "center",
            transitionDelay: "1s",
            transition: "opacity 0.5s",
            opacity: addingDistrict ? 1 : 0,
          }}
        >
          <div
            className="superadmin-container"
            style={{ backgroundColor: "whitesmoke" }}
          >
            <UserGrid initialData={adminGrid} onDataChange={(updatedData) => setAdminGrid(updatedData)} />
            <button onClick={() => handleSaveChanges(adminGrid)}
              className={`submit-button ${canSubmit ? 'can-submit' : ''} ${
                hoveringSubmit && canSubmit ? 'hovering-submit' : ''
              }`}
            >
              Submit
            </button>
          </div>
        </div>
        <Spacer height="5vmin" />
      </div>
    </>
  );
};

export default AddDistrictButton;