import _ from "lodash";
import "./StudentsScreen.css";
import { UserContext } from "../../../realm/user.context";
import ReactDataSheet from "react-datasheet";
import React, { useRef, useState, useEffect, useContext } from "react";
import "react-toastify/dist/ReactToastify.css";
import "./toast-overwrites.css";
import {
  getCleverUsersFromSchool,
  insertClass,
  insertProfiles,
  loadAllClassesFromSchool,
  loadAllClassesFromUser,
  loadAllClassesFromNonCleverUser,
  updateClassByID,
  updateProfile,
  updateSchool,
  loadNonCleverClassesFromCleverSchool,
  loadProfile,
  loadAllClassesFromSchoolV2,
  getUsersBySchoolAndDistrict,
  loadDistricts,
  updateSchoolByID,
  // updateDistrictByID,
} from "../../../realm/graphqlQueries";
import Spacer from "../../../components/Spacer";
import { toast } from "react-toastify";
import { LoadingOverlay } from "../../../components/LoadingOverlay";
import CustomEditor from "../../../components/CustomEditor";
import AddClassButton from "./AddClassButton";
import ClassPreview from "./ClassPreview";
import CreateClassScreen from "./CreateClassScreen";
import AddPeople from "./AddPeople";
import UserGrid from "./UserGrid";
import { clearExistingProfiles } from '../utils/utils';

const ToolTip = ({ person, color, onMobile, mouseLocation }) => {
  const nameRef = useRef();

  useEffect(() => {
    if (person) {
      nameRef.current = person.displayName;
    }
  }, [person]);

  return (
    <div
      className="FullNameTooltip"
      style={{
        backgroundColor: color,
        opacity: person && mouseLocation ? 1 : 0,
        transition: onMobile ? "none" : "opacity 0.25s",
        transform: `translateX(${
          mouseLocation ? mouseLocation.x + 10 : 0
        }px) translateY(${mouseLocation ? mouseLocation.y + 10 : 0}px)`,
      }}
    >
      {onMobile ? (person ? person.displayName : "") : nameRef.current}
    </div>
  );
};

const ClassesShowcase = ({
  refresh,
  isAdmin,
  district,
  onMobile,
  cleverUser,
  schoolData,
  userClasses,
  selectedSchool,
  setSelectedSchool,
  setSchoolData,
  user,
  className,
}) => {
  const [classes, setClasses] = useState([]);
  const [person, setPerson] = useState(false);
  const [loading, setLoading] = useState(false);
  const [syncing, setSyncing] = useState(false);
  const [allStudents, setAllStudents] = useState([]);
  const [allTeachers, setAllTeachers] = useState([]);
  const [allAdmins, setAllAdmins] = useState([]);
  const [editingClass, setEditingClass] = useState();
  const [mouseLocation, setMouseLocation] = useState();
  const [creatingClass, setCreatingClass] = useState(false);
  const [settingAdmins, setSettingAdmins] = useState(false);
  const [saving, setSaving] = useState(false);
  const [hovering, setHovering] = useState(false);
  const [addingPeople, setAddingPeople] = useState();
  const { realmUser } = useContext(UserContext);
  const schoolsByName = schoolData?.filter((s) => s.name === selectedSchool);
  const [teacherInfo, setTeacherInfo] = useState([]);
  const [studentInfo, setStudentInfo] = useState([]);
  const [adminInfo, setAdminInfo] = useState([]);
  const saveTimers = {
    admin: null,
    teacher: null,
    student: null,
  };  

  let school;

  if (schoolsByName.length === 1) {
    // If only one school with that name exists, choose that one
    school = schoolsByName[0];
  } else if (schoolsByName.length > 1) {
    // If there is more than one, filter by district as well
    school = schoolsByName.find((s) => s.district === district);
  }
  const usingClever = school && school.cleverId ? true : false;

  const [selectedStudents, setSS] = useState([]);
  const [selectedTeachers, setST] = useState([]);
  const [selectedClassName, setSCN] = useState("");

  const [teacherGrid, setTeacherGrid] = useState([]);
  const [studentGrid, setStudentGrid] = useState([]);
  const [adminGrid, setAdminGrid] = useState([]);
  const [originalAdmins, setOriginalAdmins] = useState([]);
  const [originalTeachers, setOriginalTeachers] = useState([]);
  const [originalStudents, setOriginalStudents] = useState([]);
  const highlightedColor = !usingClever ? "#4cc68d" : "#1464ff";

  // when this function is called we know that selectedSchool is never
  // undefined
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const generateEmptyRows = (count) => Array.from({ length: count }, () => ({ email: "" }));

  useEffect(() => {
    setStudentGrid(generateEmptyRows(10));
    setTeacherGrid(generateEmptyRows(10));
    setAdminGrid(generateEmptyRows(10));
  }, []);

  const refreshData = async () => {
    await delay(1000);

    let allUsers;
    if (school.cleverId) {
      allUsers = (await getCleverUsersFromSchool(school.cleverId, realmUser))
        .users;
    } else {
      allUsers = await getUsersBySchoolAndDistrict(
        school.name,
        school.district,
        realmUser
      );
    }

    let c;

    if (isAdmin) {
      if (cleverUser) {
        c = (
          await loadAllClassesFromSchool(
            school.cleverId ?? school.name,
            realmUser
          )
        ).classes;
        c = c.concat(
          await loadNonCleverClassesFromCleverSchool(school.cleverId, realmUser)
            .classes
        );
      } else {
        c = (await loadAllClassesFromSchoolV2(school.name, district, realmUser))
          .classes;
      }
    } else {
      if (cleverUser) {
        c = (await loadAllClassesFromUser(userClasses, realmUser)).classes;
      } else {
        c = (
          await loadAllClassesFromNonCleverUser(
            district,
            userClasses,
            realmUser
          )
        ).classes;
      }
    }

    const students = allUsers.filter((u) => u.user === "student");
    const teachers = allUsers.filter((u) => u.user === "teacher");
    const admins = allUsers.filter((u) => u.user === "admin");

    const studentData = students.map((student) => ({ email: student.email }));
    const teacherData = teachers.map((teacher) => ({ email: teacher.email }));
    const adminData = admins.map((admin) => ({ email: admin.email }));
    setStudentInfo(studentData);
    setTeacherInfo(teacherData);
    setAdminInfo(adminData);

    const finalStudentGrid = studentData.length === 0
      ? generateEmptyRows(10)
      : [...studentData, ...generateEmptyRows(10)];

    const finalTeacherGrid = teacherData.length === 0
      ? generateEmptyRows(10)
      : [...teacherData, ...generateEmptyRows(10)];
    
    const finalAdminGrid = adminData.length === 0
      ? generateEmptyRows(10)
      : [...adminData, ...generateEmptyRows(10)];

    setStudentGrid(finalStudentGrid);
    setTeacherGrid(finalTeacherGrid);
    setAdminGrid(finalAdminGrid);
    setClasses(c);
    setAllStudents(students);
    setAllTeachers(teachers);
    setAllAdmins(admins);

    setOriginalStudents([...students]);
    setOriginalTeachers([...teachers]);
    setOriginalAdmins([...admins]);

    if (!localStorage.getItem("visitedClassShowcase")) {
      toast.info(
        "This is the class showcase. All teachers and students added from your classes will show under the boxes labeled 'All Teachers' and 'All Students'",
        { autoClose: 30000, closeOnClick: true }
      );
      localStorage.setItem("visitedClassShowcase", true);
    }
  };

  useEffect(() => {
    if (selectedSchool) {
      setLoading(true);
      console.log("school id", school._id)

      refreshData().finally(() => setLoading(false));
    }
  }, [selectedSchool]);

  async function addUser(attributes) {
    let nAttributes = attributes;
    const profile = await loadProfile(attributes.email, realmUser);
    if (profile.user === null) {
      await insertProfiles([attributes], realmUser);
      return;
    } else if (
      attributes.classes !== undefined &&
      attributes.classes !== null
    ) {
      //Classes are added to a list, other attributes simply overwrite
      let nClasses = [...Set(profile.user.class.concat(attributes.classes))];
      nAttributes.classes = nClasses;
    }
    updateProfile(attributes.email, realmUser, attributes);
  }

  const processNewEntries = async (
    newEntries,
    role,
    className,
    classesLocal,
    realmUser
  ) => {
    const classNames = Object.values(classesLocal).map((cls) => cls.name);

    for (const email of newEntries) {
      const newClassName = `${email}'s class`;
      // 1. Load user profile
      let userDoc = (await loadProfile(email, realmUser)).user;

      // 2. Clear existing profiles before assigning a new role
      if (userDoc) {
        if (role === "admin") {
          await clearExistingProfiles(email, realmUser, "admin", null);
        } else if (role === "teacher") {
          await clearExistingProfiles(email, realmUser, "teacher", school._id);
        } else if (role === "student") {
          await clearExistingProfiles(email, realmUser, "student", school._id);
        }
      }

      // 3. Assign new role and update associations
      if (!userDoc) {
        userDoc = {
          email,
          user: role,
          district: school.district,
          count: 0,
          class: [],
          school: school.name,
          wellnesstimer: "30 seconds",
          schools: [school.name],
        };
      } else {
        userDoc.user = role;
        userDoc.district = school.district;
        userDoc.school = school.name;
        userDoc.schools = [school.name];
      }

      if (role === "student" && className) {
        const studentClass = classesLocal.find(
          (cls) => cls.name === className && cls.school === school.name
        );

        if (studentClass) {
          if (!studentClass.students.includes(email)) {
            studentClass.students.push(email);
            await updateClassByID(studentClass._id, className, realmUser, {
              students: studentClass.students,
            });
          }
        }
  
        if (!userDoc.class.includes(className)) {
          userDoc.class.push(className);
        }
      } else if (role === "teacher") {
        let teacherClass = className
          ? classesLocal.find((cls) => cls.name === className)
          : classesLocal.find((cls) => cls.name === newClassName);
  
        if (!teacherClass) {
          teacherClass = {
            teachers: [email],
            students: [],
            name: className || newClassName,
            school: school.name,
            district: school.district,
          };
  
          await insertClass(teacherClass, realmUser);
          classesLocal.push(teacherClass);
          classNames.push(teacherClass.name);
        } else if (!teacherClass.teachers.includes(email)) {
          teacherClass.teachers.push(email);
          await updateClassByID(teacherClass._id, teacherClass.name, realmUser, {
            teachers: teacherClass.teachers,
          });
        }
  
        if (!userDoc.class.includes(teacherClass.name)) {
          userDoc.class.push(teacherClass.name);
        }
      }
      await addUser(userDoc);
    }

    return classNames;
  };

  const removeDataFromAllClasses = async (entriesToRemove, role, classesLocal, realmUser) => {
    const key = role === "teacher" ? "teachers" : "students";

    for (const classObj of classesLocal) {
      classObj[key] = classObj[key].filter(
        (member) => !entriesToRemove.some((entry) => entry.email === member)
      );
      try {
        await updateClassByID(classObj._id, classObj.name, realmUser, {
          [key]: classObj[key],
        });
      } catch (error) {
        console.error(`Failed to update class ${classObj.name}:`, error);
      }
    }
  
    return classesLocal;
  };  

  const updateSchoolData = async (role, classNames, emailsToAdd, realmUser) => {
    if (role === "teacher") {
      await updateSchool(school.name, school.district, realmUser, {
        classes: classNames,
      });
    } else if (role === "admin") {
      await updateSchool(school.name, school.district, realmUser, {
        admins: emailsToAdd,
      });
    }
  };

  const updateStudentClass = async (
    className,
    emailsToAdd,
    classesLocal,
    realmUser
  ) => {
    const teachersClass = classesLocal.find((cls) => cls.name === className && cls.school === school.name);
    teachersClass.students = emailsToAdd;

    await updateClassByID(teachersClass._id, className, realmUser, {
      students: emailsToAdd,
    });
  };

  const updateTeacherClass = async (
    className,
    emailsToAdd,
    classesLocal,
    realmUser
  ) => {
    const teachersClass = classesLocal.find((cls) => cls.name === className && cls.school === school.name);
    teachersClass.teachers = emailsToAdd;

    await updateClassByID(teachersClass._id, className, realmUser, {
      teachers: emailsToAdd,
    });
  };

  const processEntriesToRemove = async (entriesToRemove, realmUser, fromAllStudents = false) => {
    for (const user of entriesToRemove) {
      let email = typeof user === "string" ? user : user.email;
  
      // If removing from "All" tables, clear school and class associations
      if (fromAllStudents) {
        await updateProfile(email, realmUser, {
          district: "",
          school: "",
          user: "student",
          class: [],
          schools: [],
        });
      } else {
        // Otherwise, just remove the specific class
        const userDoc = (await loadProfile(email, realmUser)).user;
        if (userDoc) {
          userDoc.class = userDoc.class.filter((className) => className !== className); // Remove only the specific class
          await updateProfile(email, realmUser, { class: userDoc.class });
        }
      }
    }
  };

  function processEntries(emailsToAdd, originalList) {
    let newEntries = emailsToAdd.filter(
      (email) => !originalList.some((user) => user.email === email)
    );

    let entriesToRemove = originalList.filter(
      (user) => !emailsToAdd.includes(user.email)
    );

    const commonElements = newEntries.filter((email) =>
      entriesToRemove.some((user) => user.email === email)
    );

    newEntries = newEntries.filter((email) => !commonElements.includes(email));
    entriesToRemove = entriesToRemove.filter(
      (user) => !commonElements.includes(user.email)
    );

    return { newEntries, entriesToRemove };
  }

  const saveChanges = async (
    role,
    gridData,
    originalList,
    setOriginalList,
    className = undefined,
    waitToRefresh = false,
    singleGridUpdate = false
  ) => {
    try {
      setSaving(true);

      let classesLocal = [...classes];

      const emailsToAdd = gridData
        .map((row) => row.email.trim())
        .filter((email) => email !== "");

      const { newEntries, entriesToRemove } = processEntries(
        emailsToAdd,
        originalList
      );

      const classNames = await processNewEntries(
        newEntries,
        role,
        className,
        classesLocal,
        realmUser
      );
  
      if ((role === "teacher" || role === "student") && className) {
        if (entriesToRemove.length > 0) {
          // Remove from specific class only
          const classToUpdate = classesLocal.find(
            (cls) => (cls.name === className && cls.school === school.name)
          );
  
          if (classToUpdate) {
            classToUpdate[role === "teacher" ? "teachers" : "students"] =
              classToUpdate[role === "teacher" ? "teachers" : "students"].filter(
                (email) => !entriesToRemove.includes(email)
              );
            await updateClassByID(
              classToUpdate._id,
              className,
              realmUser,
              classToUpdate
            );
          }
  
          // Profile updates only remove the specific class association
          for (const user of entriesToRemove) {
            let email = typeof user === "string" ? user : user.email;
            const userDoc = (await loadProfile(email, realmUser)).user;

            if (userDoc) {
              userDoc.class = userDoc.class.filter((cls) => cls !== className); // Remove only the specific class
              await updateProfile(email, realmUser, { class: userDoc.class });
            }
          }
        }
      }
  
      if (((role === "teacher" || role === "student") && !className) || role === "admin") {
        if (entriesToRemove.length > 0) {
          // Remove from all classes in the school
          for (const email of entriesToRemove) {
            classesLocal = await removeDataFromAllClasses(
              [email],
              role,
              classesLocal,
              realmUser
            );
          }
  
          await processEntriesToRemove(entriesToRemove, realmUser, true); // Explicitly remove from the school
        }
      }
      if (!singleGridUpdate) {
        await updateSchoolData(role, classNames, emailsToAdd, realmUser); 
      }

      if (role === "student" && className) {
        await updateStudentClass(
          className,
          emailsToAdd,
          classesLocal,
          realmUser
        );
      } else if (role === "teacher" && className) {
        await updateTeacherClass(
          className,
          emailsToAdd,
          classesLocal,
          realmUser
        );
      }
  
      setClasses(classesLocal);
      setOriginalList(emailsToAdd.map((email) => ({ email, user: role })));

      if (!waitToRefresh) {
        await refreshData();
      }
    } catch (error) {
      console.error("Error during saveChanges:", error);
    } finally {
      setSaving(false);
    }
  };

  const handleSaveChanges = async (
    gridType,
    gridData,
    originalData,
    setOriginalData,
    className
  ) => {
    if (!saving) {
      setSaving(true);

      try {
        await saveChanges(
          gridType,
          gridData,
          originalData,
          setOriginalData,
          className
        );
        toast.success(`${gridType} changes saved successfully!`, { closeOnClick: true, autoClose: 25000 });
      } catch (error) {
        console.error(`Error saving ${gridType} grid:`, error);
      } finally {
        setSaving(false);
      }
    } else {
      console.warn(`Save operation already in progress for ${gridType}.`);
    }
  };

  const debounceSave = (() => {
    const saveTimers = {};

    return (gridType, gridData, originalData, setOriginalData, className) => {
      if (saveTimers[gridType]) {
        clearTimeout(saveTimers[gridType]);
      }

      saveTimers[gridType] = setTimeout(() => {
        handleSaveChanges(
          gridType,
          gridData,
          originalData,
          setOriginalData,
          className
        );
      }, 500);
    };
  })();

  if (loading || syncing || saving) {
    return <LoadingOverlay></LoadingOverlay>;
  }

  return (
    <div id="ClassShowcase">
      <Spacer height="2vmin" />

      <p className="SSV2Title2" style={{ color: "#000", fontSize: "3.5vmin" }}>
        Create or select a class to manage your classes
      </p>
      {(user === "123wellness" || user === "superadmin") && (
        <p
          onMouseEnter={() => setHovering(true)}
          onMouseLeave={() => setHovering(false)}
          onClick={() => setSelectedSchool(undefined)}
          className={`view-different-school ${hovering ? 'hovering' : ''}`}
          style={{color: highlightedColor}}
        >
          Click here to view a different school
        </p>
      )}
      <div
        style={{
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "row",
          justifyContent: "space-evenly",
        }}
      >
        {!usingClever && (
          <>
            {user !== "teacher" && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                }}
              >
                <div
                  className="SSV2ClassPreview"
                  style={{
                    backgroundColor:
                      school && school.cleverId ? "#1464FF" : "#4cc68d",
                  }}
                >
                  <p className="SSV2CPTitle">Admins {school && ` at ${school.name}`} ({adminInfo.length})</p>
                  <h3 className="admin-subtext">Please add the emails of any user you would like to have access <br /> to data from the entire school- principal, counselor, etc.</h3>
                  <Spacer height="2vmin" />
                  <div className="SSV2Backdrop">
                    <UserGrid
                      initialData={adminGrid}
                      onSaveChanges={(updatedData) => {
                        setAdminGrid(updatedData);
                        debounceSave(
                          "admin",
                          updatedData,
                          originalAdmins,
                          setOriginalAdmins
                        );
                      }}
                      // set inputType to false for an admin user so admins can only add but not delete other admins
                      inputType={user === "123wellness" || user === "superadmin" || user === "admin"? true : false}
                    />
                    <Spacer height="1vmin" />
                  </div>
                  </div>
                  <div
                    className="SSV2ClassPreview"
                    style={{
                      backgroundColor:
                        school && school.cleverId ? "#1464FF" : "#4cc68d",
                    }}
                  >
                    <p className="SSV2CPTitle">
                      Teachers {school && ` at ${school.name}`} ({teacherInfo.length})
                    </p>
                    <h3 className="admin-subtext">Edit this spreadsheet to add or delete teachers from your entire school </h3>
                    <Spacer height="2vmin" />
                    <div className="SSV2Backdrop">
                      <UserGrid
                        initialData={teacherGrid}
                        onSaveChanges={(updatedData) => {
                          setTeacherGrid(updatedData);
                          debounceSave(
                            "teacher",
                            updatedData,
                            originalTeachers,
                            setOriginalTeachers
                          );
                        }}
                        inputType={user === "123wellness" || user === "superadmin" || user === "admin"? true : false}
                      />
                      <Spacer height="1vmin" />
                    </div>
                  </div>
                <div
                  className="SSV2ClassPreview"
                  style={{
                    backgroundColor:
                      school && school.cleverId ? "#1464FF" : "#4cc68d",
                  }}
                >
                  <p className="SSV2CPTitle">Students {school && ` at ${school.name}`} ({studentInfo.length})</p>
                  <h3 className="admin-subtext">Edit this spreadsheet to add or delete students from your entire school </h3>
                  <Spacer height="2vmin" />
                  <div className="SSV2Backdrop">
                  <UserGrid
                    initialData={studentGrid}
                    onSaveChanges={(updatedData) => {
                      setStudentGrid(updatedData);
                      debounceSave(
                        "student",
                        updatedData,
                        originalStudents,
                        setOriginalStudents
                      );
                    }}
                    inputType={ user === "123wellness" || user === "superadmin" || user === "admin"? true: false }
                    />
                    <Spacer height="1vmin" />
                  </div>
                </div>
              </div>
            )}
            <Spacer height="1vmin" />
          </>
        )}
        <Spacer height="1vmin" />
        {school && !school.cleverId && (
          <>
          <h3 className="class-subheader">To setup classes, please click the "Edit Class" button to add student and teacher emails to the classes below</h3>
          <h4 className="class-subtext">Please note, you have the option of copying emails directly from an EXCEL document and selecting the paste button to add them to classes or grids on this page.</h4>
          </>
        )}
        <Spacer height="1vmin" />
        {classes
          .filter(
            (c) =>
              c != undefined &&
              c.name !== "All Teachers" &&
              (!c.school || c.school === selectedSchool)
          )
          .map((c, i) => (
            <ClassPreview
              key={i}
              classData={c}
              onMobile={onMobile}
              setTeachers={setST}
              setStudents={setSS}
              setPerson={setPerson}
              setClassName={setSCN}
              allStudents={allStudents}
              allTeachers={allTeachers}
              refreshData={refreshData}
              setEditingClass={setEditingClass}
              saveChanges={saveChanges}
            />
          ))}
      </div>
      <Spacer height="5vmin" />
      {!cleverUser && (
        <>
          <AddClassButton setCreatingClass={setCreatingClass} />

          <CreateClassScreen
            school={school}
            onMobile={onMobile}
            setStudents={setSS}
            setTeachers={setST}
            setPerson={setPerson}
            setClassName={setSCN}
            allStudents={allStudents}
            allTeachers={allTeachers}
            refreshData={async () => {
              await refresh();
              await refreshData();
              toast.info(
                "Please refresh to see the roster in your newly-added school.",
                {
                  closeOnClick: true, autoClose: 25000
                }
              );
            }}
            students={selectedStudents}
            teachers={selectedTeachers}
            editingClass={editingClass}
            isCleverSchool={usingClever}
            creatingClass={creatingClass}
            className={selectedClassName}
            setEditingClass={setEditingClass}
            setCreatingClass={setCreatingClass}
            role={user}
          />

          <ToolTip
            person={person}
            onMobile={onMobile}
            mouseLocation={mouseLocation}
            color={creatingClass ? "#4cc68d" : "#1464ff"}
          />
          <AddPeople
            school={school}
            onMobile={onMobile}
            refresh={refreshData}
            allStudents={allStudents}
            allTeachers={allTeachers}
            addingPeople={addingPeople}
            setAddingPeople={setAddingPeople}
          />
        </>
      )}
    </div>
  );
};

export default ClassesShowcase;
