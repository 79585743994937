import "./StudentsScreen.css";
import React, { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import "./toast-overwrites.css";
import Spacer from "../../../components/Spacer";

const DistrictButton = ({
  data,
  onMobile,
  selectedDistrict,
  setSelectedDistrict,
  setViewingCleverDistrict,
  setViewedDistrict,
  user,
}) => {
  const [hovering, setHovering] = useState(false);
  const selected = selectedDistrict && selectedDistrict.name === data.name;
  const highlightedColor = !data.cleverId ? "#4cc68d" : "#1464ff";
  return (
    <div
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      onClick={() => setSelectedDistrict(selected ? undefined : data)}
      style={{
        padding: "2vmin",
        textAlign: "center",
        borderRadius: "5px",
        borderWidth: "2.5px",
        borderStyle: "solid",
        marginBottom: "2.5vmin",
        borderColor: highlightedColor,
        transition: "background-color 0.5s",
        cursor: hovering ? "pointer" : "default",
        backgroundColor: selected || hovering ? highlightedColor : "#fff",
      }}
    >
      <p
        style={{
          margin: 0,
          width: "25vw",
          fontSize: "3vmin",
          fontWeight: "bold",
          textAlign: "center",
          color: selected || hovering ? "#fff" : highlightedColor,
        }}
      >
        {data.name}
      </p>
      <Spacer height="2vmin" />
      <p
        style={{
          margin: 0,
          fontSize: "3vmin",
          color: selected || hovering ? "#fff" : highlightedColor,
        }}
      >
        {!data.schools || data.schools.length === 0
          ? "No schools"
          : `${data.schools.length} school${
              data.schools.length === 1 ? "" : "s"
            }`}
      </p>
      <Spacer height="2vmin" />
      <p
        style={{
          margin: 0,
          fontSize: "3vmin",
          color: selected || hovering ? "#fff" : highlightedColor,
        }}
      >
        {data.superadmins.length === 0
          ? "No superadmins"
          : `${data.superadmins.length} superadmin${
              data.superadmins.length === 1 ? "" : "s"
            }`}
      </p>
      <Spacer height="2vmin" />
      {user.user.user === "123wellness" && (
        // NOTE THIS MAY NOT WORK FOR NON CLEVER NOT SURE HOW TO TEST THIS WELL
        // data.cleverId &&
        <button
          style={{
            cursor: "pointer",
            backgroundColor: "white",
            color: ` ${
              data.cleverId ? "rgb(20, 100, 255)" : "rgb(76, 198, 141)"
            }`,
            padding: "1vmin 3vmin",
            textAlign: "center",
            textDecoration: "none",
            display: "inline-block",
            fontSize: "2.5vmin",
            margin: "1vmin",
            borderRadius: "2vmin",
            border: `0.3vmin solid ${
              data.cleverId ? "rgb(20, 100, 255)" : "rgb(76, 198, 141)"
            }`,
            transition: "0.3s",
            fontWeight: "bold",
            minWidth: "20vmin",
            minHeight: "7vmin",
          }}
          onClick={() => {
            setViewedDistrict(data.cleverId || data.name);
            setViewingCleverDistrict(data.cleverId ? true : false);
          }}
        >
          View District
        </button>
      )}
      {selected && (
        <p
          style={{
            color: highlightedColor,
            fontWeight: "bold",
            paddingTop: "2vmin",
            position: "absolute",
            marginTop: "1.5vmin",
            marginBottom: 0,
            fontSize: `${onMobile ? "3.5vmin" : "2.5vmin"}`,
            // alignSelf: "center",

            transform: `translate(${onMobile ? "-8vmin" : "-2vmin"} )`,
          }}
        >
          Click here to view more districts
        </p>
      )}
    </div>
  );
};

export default DistrictButton;